import React, {Component} from 'react';
import { connect } from 'react-redux';
import { components } from 'react-select/dist/react-select.cjs.prod';
import { bindActionCreators } from 'redux';
import HeaderComponent from '../HeaderComponent';
import {fetchIVRMapping} from '../../actions/fetchAllMapping';
import {fetchOutboundsCalls,fetchResponsiblePersonMapping} from '../../actions/IVRActions';
import Select from "react-select";
import { CSVLink } from "react-csv";
import ReactTable from 'react-table';
import '../../styles/returnOrder.css'
import {handleConnectOutboundCall, outBoundsCallsColumn, outboundColumnsCsv} from '../../config/column_fields'
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';




class IVROutBoundCallView extends Component {
    constructor(props){
        super();
        this.state = {
            allTickets : [],
            filters: {
               
            },
            showFilter: false,
            ticketMapping: {},
            page: 0,
            limit: 500,
            ticketsDataReceived: false,
            userObj: {},
            responsibleMapping:{},
            outboundCallsData:[],
            callTypeMapping:[{label:"incoming",value:"incoming"},{label:"outbound",value:"outbound"}],
            callStatusMapping:[{label:"completed",value:"completed"},{label:"missed",value:"missed"},{label:"not connected",value:"not connected"},{label:"out of office",value:"outofoffice"},{label:"from_leg_cancelled",value:"from_leg_cancelled"},{label:"from_leg_no_dial",value:"from_leg_no_dial"},{label:"from_leg_unanswered",value:"from_leg_unanswered"},{label:"to_leg_unanswered",value:"to_leg_unanswered"}],
            isNewCallModal:false,
            userPhoneNumber:'',
            agentEmailMapping: []

        }
    }

    componentDidMount(){
        this.props.fetchIVRMapping();
        this.props.fetchResponsiblePersonMapping();
        if(localStorage.getItem("userObj")){
          let userObj = JSON.parse(localStorage.getItem("userObj"));
          this.setState({
            userObj
          })
        }
    
      }
    


    componentWillMount() {
        this.props.fetchOutboundsCalls();
    }

    componentWillReceiveProps(props) {
        let { allTickets, ticketsDataReceived = false, ticketMapping,responsibleMapping,outboundCallsData } = props
        console.log('ticketMapping',ticketMapping);
        console.log('ticketsDataReceived',ticketsDataReceived);
        let agentEmailMapping = this.state.agentEmailMapping;
        let agentEmailMap = {};
        if (agentEmailMapping.length == 0) {
            (outboundCallsData || []).map(row => {
                if (row.agentEmail) agentEmailMap[row.agentEmail] = 1;
            })
            agentEmailMapping = Object.keys(agentEmailMap);
        }
        this.setState({
            allTickets: allTickets,
            ticketsDataReceived,
            ticketMapping,
            responsibleMapping,
            outboundCallsData,
            agentEmailMapping: agentEmailMapping
        })
    }
    handleFilterToggle = () => {
        let val = !this.state.showFilter;
        this.setState({
            showFilter: val,

        })
    }

    handleInputDateChangeFilters = (e, type) => {
        let { filters } = this.state;
        let { name, value } = e.target;
        filters = JSON.parse(JSON.stringify(filters));
        filters[name] = value;
        this.setState({
            filters
        })
    }
    handleInputChanges = (e) => {
        let {  value } = e.target;
        this.setState({
            userPhoneNumber:value
        })
    }
    handleSelectChangeFilters = (e, name) => {
        let value = (e || []).map(option => option.value);
        // let value = e.value 
        let {filters} = this.state;

        this.setState({
            filters: {
                ...filters,
                [name]: value
            }
        })

    }

    fetchMoreTickets = () => {
        let { page, limit, allTickets, filters } = this.state;
        // this.props.fetchAllTickets(page + 1,limit,allTickets,filters);
        this.setState({
            page: page + 1,
            ticketsDataReceived: false
        })
    }
    handleCloseNewCallModal = ()=>{
        this.setState({
            isNewCallModal:false
        })

    }
    handleOpenNewCallModal = ()=>{
        this.setState({
            isNewCallModal:true
        })

    }
    handleConnectOutgoingCall = ()=>{
        let {userPhoneNumber,userObj} = this.state
        if(!userPhoneNumber || userPhoneNumber.length!=10)
        return alert("Please Enter 10 digit mobile number")
        let email = userObj.email;
        handleConnectOutboundCall(email, userPhoneNumber)
        this.handleCloseNewCallModal()
    }


    onApplyFilter = () => {
        let { limit, filters } = this.state;
        console.log('filters', filters);
        let filtersValue = {}
        for(let key in filters){
            if(filters[key]){
                filtersValue[key] = filters[key]
            }

        }
        if(Object.keys(filtersValue).length){
        this.props.fetchOutboundsCalls(filtersValue)
        }
        // this.props.fetchAllTickets(0, limit, [], filters);
        // this.setState({
        //     page: 0,
        //     ticketsDataReceived: false
        // })
    }

    // handleEditReturnOrderDataClick = (row)=>{
    //     let{uniqueRowId} = row;
    //     this.setState({
    //         returnOrderEditFlag:{
    //             ...this.state.returnOrderEditFlag,
    //             [uniqueRowId]:true
    //         },
    //         editedReturnOrderData:{
    //             ...this.state.editedReturnOrderData,
    //             [uniqueRowId]:row
    //         }
    //     })
    // }

    // handleCancelReturnOrderDataClick = (row)=>{
    //     let {uniqueRowId} = row.original;
    //     this.setState({
    //         returnOrderEditFlag:{
    //             ...this.state.returnOrderEditFlag,
    //             [uniqueRowId]: false
    //         },
    //         editedReturnOrderData:{
    //             ...this.state.editedReturnOrderData,
    //             [uniqueRowId]:false
    //         }
    //     })


    // }

    // handleSaveReturnOrderDataClick = (row) => {
    //     let {uniqueRowId} = row.original;
    //     let KeysCanBeChanged = ["qcChecked", "qcStatus", 'sent', "damageClaimAmount","damageClaimDate","damageClaimId","itemReceived","putaway","qcFeedback"]
    //     let {editedReturnOrderData,returnOrders, userObj} = this.state
    //     let newData = editedReturnOrderData[uniqueRowId];
    //     let newDataToSend = {};
    //     let updatedBy = userObj.email;
    //     KeysCanBeChanged.map((key)=>{
    //         if(newData[key]!= row.original[key]){
    //             newDataToSend[key] = newData[key];
    //         }
    //     })
        
    //     newDataToSend.updatedBy = updatedBy;
    //     if(!Object.keys(newDataToSend).length){
    //         return;
    //     }
    //     this.props.updateRetunOrderData(uniqueRowId,newDataToSend, returnOrders)
    //     this.setState({
    //         returnOrderEditFlag:{
    //             ...this.state.returnOrderEditFlag,
    //             [uniqueRowId]: false
    //         },
    //         editedReturnOrderData:{
    //             ...this.state.editedReturnOrderData,
    //             [uniqueRowId]:false
    //         }
    //     })

    // }
    
    multiSelectType = (name, options, placeholder) => {
        return (
            <div className="form-group" style={{ width: '100%' }}>
                <Select
                    isSearchable
                    onChange={(e) => { this.handleSelectChangeFilters(e, name) }}
                    placeholder={placeholder}
                    name={name}
                    options={options}
                    isMulti
                />
            </div>
        )
    }
    inputType = (name, placeholder, type) => {
        return (
            <div className="form-group">
                <div className="input-group mb-3">
                   {type=="date" || type=="time" &&  <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">{placeholder}</span>
                    </div>}

                    <input className="form-control customInput" type={type} name={name} placeholder={placeholder} onChange={(e) => this.handleInputDateChangeFilters(e)} />
                </div>
            </div>
        )
    }

    // handleOrderChanges = (e,row)=>{
    //     let {name, value} = e.target;
    //     let {uniqueRowId} = row.original
    //     let {editedReturnOrderData} = this.state;
    //     editedReturnOrderData = JSON.parse(JSON.stringify(editedReturnOrderData));
    //     editedReturnOrderData[uniqueRowId][name] = value;
    //     this.setState({
    //         editedReturnOrderData
    //     })


    // }
    // InputTypeText = (name, value, type, row ) => {
    //     return (
    //         <input type={type} name ={name} value = {value} className="form-control"  onChange={(e) => this.handleReturnOrderChanges(e, row)} ></input>
    //     )
    // }
    // renderInputCell = (row) => {
    //     let { id, type } = row.column;
    //     let {returnOrderEditFlag} = this.state
    //     let checked = ["damaged/missing product","wrong product"]
    //     return (
    //             returnOrderEditFlag[row.original.uniqueRowId]  && checked.includes(row.original.qcStatus)? this.InputTypeText( id,  this.state.editedReturnOrderData[id] , type, row): row.value
    //     )
    // }

    // ticketSelect = (name, options,  placeholder, row, value='' ) => {
    //     // console.log('options', options);
    //     return (
    //         <div style={{ width: '100%' }}>
    //     <select
    //       onChange={(e) => { this.handleReturnOrderChanges(e,row) }}
    //       className="form-control" id="sel1" name={name} value={value}
    //     >
    //       <option>{placeholder}</option>
    //       {(options ? options : []).map(e => <option key={e.value}>{e.label}</option>)}
    //     </select>
    //   </div>
    //     )
    // }


    render(){ 
        const modalStyles = {
            modal: {
              width: '40%',
              maxWidth: '40%',
              padding: '40px',
              background: '#f7f7f7',
              textAlign: 'center'
            }
        };
        let {ticketMapping,ticketsDataReceived, allTickets, showFilter,responsibleMapping, handleSelectChangeFilters ,filters,outboundCallsData, callStatusMapping,callTypeMapping, isNewCallModal, agentEmailMapping} = this.state
        let { handleFilterToggle, inputType, onApplyFilter, fetchMoreTickets, handleCloseNewCallModal, handleOpenNewCallModal, handleConnectOutgoingCall,handleInputChanges } = this


        return (
            <div>
                <HeaderComponent/>
                <div className="container-fluid">
                <div style={{ "marginTop": 60 }} className="topbar-section allLeadViewFilters">
                    <div className="col-md-4"><b>Filters | {this.state.showFilter ? <i onClick={handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye-slash"></i> : <i onClick={handleFilterToggle} style={{ cursor: "pointer" }} class="fa fa-eye"></i>} </b></div>
                    <hr className={showFilter ? "showFilter" : "hideFilter"}></hr>
                    <div className={`${showFilter ? "showFilter" : "hideFilter"} ${"row"}`}>
                        <div className="col-md-3">
                            {inputType("startDate", "Start  Date", "date")}
                        </div>
                        <div className="col-md-3">
                            {inputType("endDate", "End  Date", "date")}
                        </div>
                        <div className="col-md-3">
                            {inputType("startTime", "Start  Time", "time")}
                        </div>
                        <div className="col-md-3">
                            {inputType("endTime", "End  Time", "time")}
                        </div>
                        <div className="col-md-3">
                                {this.multiSelectType("callType", callTypeMapping, "Call Type")}
                        </div>
                        <div className="col-md-3">
                                {this.multiSelectType("status", callStatusMapping, "Call Status")}
                        </div>
                        <div className="col-md-3">
                                {this.multiSelectType("agentEmail", agentEmailMapping.map(email => ({ label: email, value: email })), "Responsible")}
                        </div>
                        <div className="col-md-3">
                            {inputType("customerPhoneNumber", "phone number", "number")}
                        </div>
                        
                       
                        

                        
                        <div className="col-md-3">
                            <button onClick={onApplyFilter} className="btn btn-success">Apply filter</button>
                        </div>
                    </div>

                </div>
                <div className="callContainer">
                    <button onClick={()=>handleOpenNewCallModal()} className="btn btn-success">New Call</button>
                    <b className="ml-3" style={{ padding: '10px 0' }}>Total Calls: {outboundCallsData ? outboundCallsData.length : 0}</b>
                    <div>
                    <CSVLink filename={"Calls Data.csv"} data={outboundCallsData || []} headers={outboundColumnsCsv} className="btn btn-light">
                                Download CSV <i className="fa fa-download"></i>
                    </CSVLink>
                    </div>
                 </div>
                 <Modal open={isNewCallModal} onClose={handleCloseNewCallModal} styles={modalStyles} center>
                            <div style={{ border: "1px solid #92b0b3", padding: "5px" }}>
                            <div className=" container-fluid uploadBox">
                            <input style={{ width: "50 %", height: '42px', fontWeight: "bold" }} type="number" name='phoneNumber' onChange={(e)=>handleInputChanges(e)}></input>
                          
                            <button style={{ height: '38px', fontWeight: "bold" , marginLeft:'10px'}} className="btn btn-success btn-sm" onClick={()=>handleConnectOutgoingCall()}>Call</button>
                            </div>
                            </div>
                        </Modal>
                {/* <div className="moreDataContainer">
                    <div>
                        <button className="btn btn-light" style={{ margin: '0 10px' }} onClick={() => fetchMoreTickets()}>More Tickets</button>

                    </div>
                    <div>
                    <CSVLink filename={"ticket Data.csv"} data={allTickets || []}   className="btn btn-light">
                                Download CSV <i className="fa fa-download"></i>
                    </CSVLink>
                    </div>
                    <div style={{ float: "right", marginLeft:'5%' }}>
                            <Link to="/zunsolar/createTicket" target="_blank"><button className="createLeadBtn">Create Ticket</button></Link>
                        </div>

                </div> */}


                <ReactTable
                    filterable={true}
                    showPagination={true}
                    sortable={true}
                    data={outboundCallsData}
                    columns={outBoundsCallsColumn}
                    defaultSorted={[{ id: "createdOn", desc: false }]}
                    style={{ height: "90vh", textAlign: 'center' }}
                    defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
                />

            </div>

            </div>
        )
    }
}

function mapStateToProps(state) {
    let ticketMapping = state.fetchAllMapping && state.fetchAllMapping.IVRMapping || {}
    console.log('ticketMapping2',ticketMapping);
    let {allTickets, ticketsDataReceived,responsibleMapping,outboundCallsData} = state.IVRReducer
    // let 
    return {
        ticketMapping,
        allTickets,
        ticketsDataReceived,
        responsibleMapping,
        outboundCallsData
    }

   
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ fetchOutboundsCalls, fetchIVRMapping,fetchResponsiblePersonMapping }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(IVROutBoundCallView);
