import React, { Component } from 'react';
import  '../../styles/returnOrder.css'
import { bindActionCreators } from 'redux';
import HeaderComponent from '../HeaderComponent';
import { fetchIVRMapping } from '../../actions/fetchAllMapping';
import { fetchSingleTicket, updateSingleTicketData, fetchResponsiblePersonMapping,addTicketComment,fetchAllTicketComment, fetchTicketHistory, createTicketInMaintenance } from '../../actions/IVRActions';
import { connect } from 'react-redux';
import Select from 'react-select'
import ReactTable from "react-table";
import moment from 'moment'
import {handleDownloadTicketAttachment, ticketColumns, ticketHistoryColumn} from '../../config/column_fields'
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import axios from "axios"
import url from "../../config";
import {getUnicommerceMapping} from '../../actions/zunsolarPiInvoiceAction';

const responsiblePeOptions = [ { value: 'ZR421', name: 'Manish', label: 'ZR421 Manish' },
{ value: 'ZR601', name: 'Gaurav', label: 'ZR601 Gaurav' },
{ value: 'ZR556', name: 'Suman', label: 'ZR556 Suman' },
{ value: 'ZR583', name: 'Chaluvaraju', label: 'ZR583 Chaluvaraju' },
{ value: 'ZR506', name: 'Rakesh', label: 'ZR506 Rakesh' },
{ value: 'ZR075', name: 'Test', label: 'ZR075 Test' },
{ value: 'ZR592', name: 'Kunal', label: 'ZR592 Kunal' },
{ value: 'ZR678', name: 'Amol', label: 'ZR678 Amol' },
{ value: 'ZR656', name: 'Harikishan', label: 'ZR656 Harikishan' },
{ value: 'ZR626', name: 'Ravi', label: 'ZR626 Ravi' },
{ value: 'ZR641', name: 'Murali', label: 'ZR641 Murali' },
{ value: 'ZR700', name: 'Rahul SR', label: 'ZR700 Rahul SR' },
{ value: 'ZR716', name: 'Puneeth JS', label: 'ZR716 Puneeth JS' },
{ value: 'ZR702', name: 'Kanhya', label: 'ZR702 Kanhya' },
{ value: 'ZR506', name: 'Rakesh', label: 'ZR506 Rakesh' },
{ value: 'ZR656', name: 'Harikishan', label: 'ZR656 Harikishan' },
{ value: 'ZR733', name: 'Ashish', label: 'ZR733 Ashish' },
{ value: 'ZR725', name: 'Uday', label: 'ZR725 Uday' },
{ value: 'ZR739', name: 'Anjesh', label: 'ZR739 Anjesh' },
{ value: 'ZR701', name: 'Amit Kumar', label: 'ZR701 Amit Kumar' },
{ value: 'ZR744', name: 'J Aftab Ahmed', label: 'ZR744 J Aftab Ahmed' },
{ value: 'ZR733', name: 'Ashish', label: 'ZR733 Ashish' },
{ value: 'ZR754', name: 'Aakash', label: 'ZR754 Aakash' },
{ value: 'ZR760', name: 'Shaik Arshad', label: 'ZR760 Shaik Arshad' },
{ value: 'ZR756', name: 'Chetan Kshirsagar', label: 'ZR756 Chetan Kshirsagar' },
{ value: 'ZR768', name: 'Sushant', label: 'ZR768 Sushant' },
{ value: 'ZR776', name: 'Sonu Kumar', label: 'ZR776 Sonu Kumar' },
{ value: 'ZR808', name: 'Yogesh Jogdand', label: 'ZR808 Yogesh Jogdand' },
{ value: 'ZR873', name: 'Surya Prakash', label: 'ZR873 Surya Prakash' },
{ value: 'ZR876', name: 'Rohit Yadav', label: 'ZR876 Rohit Yadav' },
{ value: 'ZR879', name: 'Suresh Kumar', label: 'ZR879 Suresh Kumar' },
{ value: 'ZR884', name: 'Syed nawaz Khadri', label: 'ZR884 Syed nawaz Khadri' } ];

const purposeOfVisitOptions = [
  { value: 'Installation', label: 'Installation' },
  { value: 'RO pickup', label: 'RO pickup' },
  { value: 'Relocation', label: 'Relocation' },
  { value: 'Maintenance', label: 'Maintenance' },
  { value: 'Complaint', label: 'Complaint' }
];

//this.props.match.params.id
class SingleIVRTicket extends Component {
  constructor() {
    super();
    this.state = {
      ticketId: '',
      singleTicketData: {},
      ticketMapping: {},
      userObj: [],
      edit: false,
      newTicketData: {},
      loading: true,
      responsibleMapping: {},
      allComments: [],
      newComment: { ticketId: '', comment: '' },
      relatedTicketData: [],
      ticketComments:[],
      ticketHistoryData:[],
      disableCreateMaintenance: false,
      createInMaintenanceModal: false,
      zunpureClientAddress: {},
      zunpureSelectedPe: {},
      purposeOfVisit: '',
      maintenanceDate: '',
      subOrderIdOptions: [],
      productMapping: []
    }
  }

  componentDidMount() {
    this.props.fetchIVRMapping();
    this.props.fetchResponsiblePersonMapping();
    let ticketId = this.props.match.params.ticketId
    this.props.fetchSingleTicket(ticketId)
    this.props.fetchAllTicketComment(ticketId);
    this.props.fetchTicketHistory(ticketId);
    this.props.getUnicommerceMapping();
    if (localStorage.getItem("userObj")) {
      let userObj = JSON.parse(localStorage.getItem("userObj"));
      this.setState({
        userObj,
        ticketId
      })
    }
  }
  // componentWillMount(){
  //     this.props.fetchSingleTicket(this.props.match.ticketId)
  // }

  componentWillReceiveProps(props) {
    let { ticketMapping, singleTicketDetails = {}, responsibleMapping,ticketComments,ticketHistory=[], unicommerceMapping = [] } = props;
    let { singleTicketData = {}, relatedTicketData = [] } = singleTicketDetails

    let productMapping = [];

    productMapping = unicommerceMapping.map(product => {
      let variant = (product.variantType && product.variantType != "NULL") ? product.variantType : '';
      return { label: product.pID + " " + product.productName + " " + variant, value: product.pID, productName: product.productName }
    })

    this.setState({
      ticketMapping: ticketMapping,
      singleTicketData,
      newTicketData: singleTicketData,
      responsibleMapping,
      relatedTicketData,
      ticketComments,
      ticketHistoryData:ticketHistory,
      productMapping
    })
  }



  handleEdit = () => {
    if (this.state.edit) {
      this.setState({
        edit: false
      })
    } else {
      this.setState({
        newTicketData: { ...this.state.singleTicketData },
        edit: true
      })
    }
  }

  handleInputChangeComment = (e) => {
    let { name, value } = e.target;

    this.setState({
      newComment: {
        [name]: value,
        ticketId: this.state.ticketId
      }
    })
  }


  handleInputChange = (e) => {
    let { name, value } = e.target;

    this.setState({
      newTicketData: {
        ...this.state.newTicketData,
        [name]: value
      }
    })
  }

  handleTimeFormat = (time)=>{
    let date = new Date(time);
    console.log('date',date);
     date.setMinutes(date.getMinutes() + 330);
     date=moment(date).format("YYYY-MM-DD HH:mm:ss")
     return date;
  }

  handleSelectChange = (e, name) => {
    let { value } = e;

    if(name == "productId") {
      this.setState({
        newTicketData: {
          ...this.state.newTicketData,
          "productId": value,
          "productName": e.productName
        }
      })
      return;
    }

    this.setState({
      newTicketData: {
        ...this.state.newTicketData,
        [name]: value
      }
    })

    // let value = (e || []).map(option => option.value);

  }

  handleSubmitComment = () => {
    let { newComment,ticketId , userObj } = this.state;
    let apiData = {
      commentBody: newComment.comment,
      ticketId: ticketId,
      commentedBy: userObj.email
    }
    this.props.addTicketComment(apiData);
  }
  handleCancelEdit = () => {
    this.setState({
      edit: false
    })
  }
  handleSubmitUpdatedTicketData = () => {
    let { ticketId, singleTicketData, newTicketData, responsibleMapping, userObj, newComment } = this.state
    let updatedNewData = {};
    if (!newComment.comment) {
      return alert("Please Enter a comment")
    }

    let file = newTicketData.attachment
    let KeysCanBeChanged = ["type", "subtype", "ticketType", "description", "responsibleTeam", "responsiblePerson", "reasonforTicketClosed", "complaintStatus","nextActionDate", "orderId", "subOrderId", "productId", "productName"]
    KeysCanBeChanged.map((key) => {
      if (newTicketData[key] && newTicketData[key] != singleTicketData[key]) {
        updatedNewData[key] = newTicketData[key];
      }
    })
    if (["Resolved", "Closed"].includes(updatedNewData.complaintStatus) && !updatedNewData.reasonforTicketClosed) {
      return alert("Reason is missing")

    }
    if(updatedNewData.responsibleTeam && !updatedNewData.nextActionDate){
      return alert("Next Action Date is missing")

    }
    let user = userObj.email
    const formData = new FormData()
    formData.append("ticketId", ticketId);
    formData.append("user", user)
    formData.append("updatedData", JSON.stringify(updatedNewData))
    if (file) {
      formData.append("attachment", file)
    }

    // return;
    this.handleSubmitComment();

    this.props.updateSingleTicketData(ticketId, singleTicketData, updatedNewData, user, formData)
    this.setState({
      edit: false,
      singleTicketData: { ...this.state.newTicketData },
    })
  }

  inputType = (name, value, type, placeholder, required=false) => {
    return <input type={type} placeholder={placeholder} required = {required} className="form-control" name={name} value={value} onChange={(e) => this.handleInputChange(e)} />
  }
  handleFileChange = (e) => {
    let files = e.target.files
    let {newTicketData} = this.state;
    // let allFiles = {...files} || {}
    // console.log('allFiles',allFiles);
    let { name } = e.target;
    newTicketData = JSON.parse(JSON.stringify(newTicketData));
    newTicketData[name] = files[0]

   this.setState({
        newTicketData
    })
};
  FileTypeInput = ({name, type, label, placeholder, required=false }) => {
    return (<div
            className="col-12 "
            style={{ width: "100%", paddingTop: "1%" }}
        >
            <div className="form-group">
                <div className="mb-3">
                    {/* <label>{label}</label> */}
        <input type={type} className="form-control" multiple  placeholder ={placeholder} name={name}  onChange={(e) => this.handleFileChange(e)} required={required}></input>
        </div>
        </div>
        </div>
    )
}
  SelectType = ({ label, name, options, placeholder, disabled, defaultValue, required = false }) => {
    return (
      <div
        className="col-12 "
        style={{ width: "100%", padding: "0" }}
      >
        <div className="form-group">
          <div className="mb-3">
            {/* <label>{label}</label> */}
            <Select
              isSearchable
              onChange={(e) => { this.handleSelectChange(e, name) }}
              placeholder={placeholder}
              name={name}
              options={options}
              isDisabled={disabled}
              required={required}
              value = {defaultValue}
            />
          </div>
        </div>
      </div>
    )
  }

  closeCreateMaintenanceModal = () => {
    this.setState({
      createInMaintenanceModal: false
    })
  }

  handleCreateInMaintenance = async () => {
    // this.setState({
    //   disableCreateMaintenance: true,
    //   createInMaintenanceModal: true
    // })
    this.setState({
      createInMaintenanceModal: true
    });
    let { ticketId, singleTicketData } = this.state;
    let { roClientId } = singleTicketData;
    let getClientAddressResponse = await axios.post(`https://zuntalks.in:8089/zunpure/auth/api/getclientaddress`, {
      key: '0D45rwmKnijvMfQ',
      clientId: roClientId
    });
    getClientAddressResponse = getClientAddressResponse.data;
    if(getClientAddressResponse.error) {
      return;
    }
    console.log("getClientAddressResponse", getClientAddressResponse);
    this.setState({
      zunpureClientAddress: getClientAddressResponse
    })
    // let { ticketId, singleTicketData } = this.state;
    // let { roClientId } = singleTicketData;
    // if(!ticketId || !roClientId) return;
    // this.props.createTicketInMaintenance(ticketId, roClientId);
  }

  handleSelectPEChange = (e, name) => {
    console.log(e);
    if(name == "responsiblePe") {
      this.setState({
        zunpureSelectedPe: e
      })
    }
    else if(name == "purposeOfVisit") {
      this.setState({
        purposeOfVisit: e.value
      })
    }
    else if(name == "maintenanceDate") {
      this.setState({
        maintenanceDate: e.target.value
      })
    }
  }

  handleCreateInMaintenanceSubmit = () => {
    console.log("handleCreateInMaintenanceSubmit");
    this.setState({
      disableCreateMaintenance: true
    })
    let { ticketId, singleTicketData, zunpureSelectedPe, purposeOfVisit, maintenanceDate } = this.state;
    let { roClientId, description = '' } = singleTicketData;
    let { value, name } = zunpureSelectedPe;
    if(!value || !name) {
      alert("Please select responsible PE");
      return;
    }
    if(!purposeOfVisit) {
      alert("Please select purpose of visit");
      return;
    }
    if(!maintenanceDate) {
      alert("Please select maintenance date");
      return;
    }
    if(!roClientId || !ticketId) {
      alert("Some error occured");
      return;
    }
    console.log(ticketId, roClientId, {
      visitedBy: name,
      visitedId: value
    });
    this.props.createTicketInMaintenance(ticketId, roClientId, {
      visitedBy: name,
      visitedId: value
    }, description, purposeOfVisit, maintenanceDate);
    this.setState({
      createInMaintenanceModal: false
    });
  }

  TicketTextArea = ({
    label,
    name,
    type,
    inputParams = {},
    required = true,
    disabled = false,
  }) => {
    let { newComment = {} } = this.state;
    let value = newComment[name] || "";
    return (
      <div
        className="col-10"
        style={{ width: "100%", paddingTop: "1%" }}
      >
        <div className="form-group">
          <div className="mb-3">
            <label>{label}</label>
            <textarea
              rows={5}
              className="form-control customInput"
              type={type}
              name={name}
              placeholder={label}
              value={value}
              onChange={(e) => this.handleInputChangeComment(e)}
              {...inputParams}
              required={required}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    );
  };

  handleOrderIdChange = (e) => {
    let { name, value } = e.target;
    console.log("handleOrderIdChange", name, value);
  }

  checkValidOrderId = async (e) => {
    try {
      let { value } = e.target;
      let [ orderDataResponse = {}, subOrdersResponse = {} ] = await Promise.all([
        axios.post(`${url.pathBackend}zunsolar/ordertracker/fetchorderdata`, { orderId: value }),
        axios.post(`${url.pathBackend}zunsolar/ordertracker/fetchsuborders`, { orderId: value })
      ]) || [];
      let orderData = orderDataResponse.data || {};
      let subOrders = subOrdersResponse.data || {};
      orderData = orderData.data || {};
      subOrders = subOrders.data || [];
      if(!orderData.orderId || !subOrders.length) {
        alert("Please enter valid order id");
        return;
      }
      let subOrderIdOptions = subOrders.map(row => row.subOrderId);
      console.log("subOrderIdOptions", subOrderIdOptions);
      this.setState({
        newTicketData: {
          ...this.state.newTicketData,
          "orderId": value
        },
        subOrderIdOptions: subOrderIdOptions
      })
      return;
    }
    catch(error) {
      console.log("Error in checkValidOrderId", error);
      return;
    }
  }
  // selectType = (name, value, options) => {
  //     return (
  //       <div style={{ width: '100%' }}>
  //         <select
  //           onChange={(e) => { this.handleInputChange(e) }}
  //           className="form-control" id="sel1" name={name} value={value}
  //         >
  //           <option>not selected</option>
  //           {(options ? options : []).map(e => <option key={e}>{e}</option>)}
  //         </select>
  //       </div>
  //     )
  //   }
  render() {
    let { ticketId, ticketMapping, newTicketData, singleTicketData, responsibleMapping, relatedTicketData, ticketComments,ticketHistoryData, zunpureClientAddress } = this.state;
    let { handleCancelEdit, handleSubmitUpdatedTicketData, handleEdit, SelectType, TicketTextArea, handleSubmitComment,FileTypeInput, handleTimeFormat, handleCreateInMaintenance, handleCreateInMaintenanceSubmit } = this

    const style = {
      hide: {
        display: 'none'
      },
      show: {
        display: ''
      }
    }

    const modalStyles = {
      modal: {
        width: '40%',
        maxWidth: '40%',
        padding: '40px',
        background: '#f7f7f7',
        textAlign: 'center'
      }
    }

    return (
      <div>
        <HeaderComponent />
        <div className="container-fluid" style={{ marginTop: "80px" }}>
          <div className="topbar-section" >
            <div className="row">
              <div className="col-md-9 col-xs-12 topbar-left">
                <div className="col-md-12">
                  <strong>Ticket ID:  </strong>
                  <strong style={{ marginRight: "5px" }}>{ticketId}</strong>

                </div>
              </div>
              <div className="col-md-3 col-xs-12 topbar-right">
                <div className="col-md-12 col-xs-12">
                  <span style={this.state.edit ? style.hide : style.show}><i onClick={handleEdit} style={{ cursor: "pointer", float: "right", marginTop: "8px" }} className="fas fa-pen"></i></span>
                  { singleTicketData.roClientId && singleTicketData.type == 'RO' && !singleTicketData.createdInMaintenance ? <span style={this.state.edit ? style.hide : style.show}><button className="button-edit" onClick={(e) => handleCreateInMaintenance()} disabled={this.state.disableCreateMaintenance}>Create in maintenance</button></span> : "" }
                  <button style={this.state.edit ? style.show : style.hide} className="button-edit" onClick={(e) => handleSubmitUpdatedTicketData()}>Save</button>
                  <button style={this.state.edit ? style.show : style.hide} className="button-cancel" onClick={(e) => handleCancelEdit()}>Cancel</button>
                </div>
              </div>
            </div>
          </div>

          <div className="bottom-section">
            <div className="row">
              <div className="col-md-4 col-12 row customer-info">
              <div style={{ borderRight: '0px', width:'100%' }}>
                <table>
                <tr>
                    <td>Order Id</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{singleTicketData.orderId ? <a href={`https://zrm.zunroof.com/zunsolar/order/${singleTicketData.orderId}`} target="_blank">{singleTicketData.orderId}</a> : ""}</span>
                    <span style={this.state.edit ? style.show : style.hide}>
                      <input type="text" onChange={this.handleOrderIdChange} onBlur={this.checkValidOrderId} name="orderId" className="form-control"></input>
                    </span>
                    </td>
                  </tr>
                  <tr>
                    <td>SubOrder Id</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{singleTicketData.subOrderId}</span>
                    <span style={this.state.edit ? style.show : style.hide}>
                    <SelectType label="Sub Order Id" name="subOrderId" options={(this.state.subOrderIdOptions || []).map(val => ({ label: val, value: val }))} placeholder="select suborder id" defaultValue={{label:newTicketData.subOrderId, value: newTicketData.subOrderId}} required={true} />
                    </span>
                    </td>
                  </tr>
                    <tr>
                      <td>Product Id</td>
                      <td><span style={this.state.edit ? style.hide : style.show}>{singleTicketData.productId}</span>
                        <span style={this.state.edit ? style.show : style.hide}>
                          <SelectType label="Product Id" name="productId" options={(this.state.productMapping || [])} placeholder="select product id" defaultValue={(this.state.productMapping || []).find(val => val.value == newTicketData.productId)} required={true} />
                        </span>
                      </td>
                    </tr> 
                  <tr>
                    <td>Product Name</td>
                    <td><span>{singleTicketData.productName}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>RO Client Id</td>
                    <td><span>{singleTicketData.roClientId ? <a href={`http://zuntalks.in:3030/order/${singleTicketData.roClientId}`} target="_blank">{singleTicketData.roClientId}</a> : ""}</span>
                    </td>
                  </tr>

                  <tr>
                    <td>RO Product Id</td>
                    <td><span>{singleTicketData.roProductid}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Platform</td>
                    <td><span>{singleTicketData.platform}</span>
                    </td>
                  </tr>         
                 
                  
                </table>
              </div>
              </div>
              <div className="col-md-4 col-12 row customer-info">
              <div style={{ borderRight: '0px', width:'100%' }}>
                <table>
                <tr>
                    <td>Phone Number</td>
                    <td><span>{singleTicketData.phoneNumber}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Customer Name</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{singleTicketData.name}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("name", newTicketData.name, "name")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Type</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{singleTicketData.type}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        <SelectType label="Type" name="type" options={ticketMapping.type || []} placeholder="select Type" defaultValue={{label:newTicketData.type, value: newTicketData.type}} required={true} />

                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>Sub-Type</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{singleTicketData.subtype}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        <SelectType label="Sub-Type" name="subtype" options={newTicketData && newTicketData['type'] && ticketMapping?.typeSubTypeMapping[newTicketData['type']] || []} placeholder="select Sub-Type"  defaultValue={{label:newTicketData.subtype, value: newTicketData.subtype}} required={true} />


                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{singleTicketData.description}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("description", newTicketData.description, "description")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Ticket Status</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{singleTicketData.complaintStatus}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        <SelectType label="Status" name="complaintStatus" options={ticketMapping.complaintStatus || []}  defaultValue={{label:newTicketData.complaintStatus, value: newTicketData.complaintStatus}} placeholder="select status" required={true} />


                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td>Reason</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{singleTicketData.reasonforTicketClosed}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("reasonforTicketClosed", newTicketData.reasonforTicketClosed, "text", "Reason", ["Resolved","Closed"].includes(newTicketData.complaintStatus))}
                      </span>
                    </td>
                  </tr>
                  
                </table>
              </div>
              </div>
              <div className="col-md-4 col-12 row customer-info">
              <div style={{ borderRight: '0px', width:'100%' }}>
                <table>

                <tr>
                    <td>Responsible Team</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{singleTicketData.responsibleTeam}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        <SelectType label="Responsible Team" name="responsibleTeam" options={responsibleMapping.responsibleTeam || []} placeholder="select Responsible Team"   defaultValue={{label:newTicketData.responsibleTeam, value: newTicketData.responsibleTeam}} required={true} />


                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Responsible Person</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{singleTicketData.responsiblePerson}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        <SelectType label="Responsible Person" name="responsiblePerson" options={newTicketData && newTicketData.responsibleTeam && responsibleMapping.responsiblePerson && responsibleMapping.responsiblePerson[newTicketData.responsibleTeam] || []} placeholder="select Responsible Person"   defaultValue={{label:newTicketData.responsiblePerson, value: newTicketData.responsiblePerson}} required={true} />


                      </span>
                    </td>
                  </tr>

                  <tr>
                  <td>Next Action Date</td>
                    <td><span style={this.state.edit ? style.hide : style.show}>{singleTicketData.nextActionDate}</span>
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                        {this.inputType("nextActionDate", newTicketData.nextActionDate, "date","Next Action Date")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Created By</td>
                    <td><span>{singleTicketData.createdBy}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Created At</td>
                    <td><span>{handleTimeFormat(singleTicketData.createdAt)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>updated By</td>
                    <td><span>{singleTicketData.updatedBy}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>updated At</td>
                    <td><span>{handleTimeFormat(singleTicketData.updatedAt)}</span>
                    </td>
                  </tr>
                  
                  
                  <tr>
                    <td>Attachment</td>
                    <td>
                      {
                        singleTicketData?.fileUrls?.map((fileUrl, index)=>(
                          <button key={index}  className="btn btn-success btn-sm col-md-4 p-1 m-2" disabled = {!fileUrl} onClick= {()=>{handleDownloadTicketAttachment(fileUrl)}} >File {index+1}</button>                      

                        ))
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Add File</td>
                    <td>
                      {/* <span style={this.state.edit ? style.hide : style.show}>{singleTicketData.description}</span> */}
                      <span style={this.state.edit ? style.show : style.hide} className="input-group">
                      <FileTypeInput name = "attachment"  type= "file" label = "Attachement" placeholder  = "Attachement"  />
                      
                      </span>
                    </td>
                  </tr>
                </table>
              </div>
              </div>
            </div>
            <div className="row customer-info">
              <div style={{ borderRight: '0px', width:'100%' }}>
                <div className=" customer-info">
              {/* <strong style={{ padding: "18px", marginTop:"18px" }}>Comment Section </strong> */}
              <div>
                {ticketComments.map((commentData, index)=>(
                  <div key={index} className='commentSection'> 
                  <div className='commentedBy'>
                    <b>{commentData.commentedBy}</b>
                    <span className='commentTime'>{commentData.timestamp}</span>
                  </div>
                  <div className='commentedBody'> 
                    {commentData.commentBody}
                    </div>
                  </div>
                ))}

              </div>
              <div className='row'>
              <TicketTextArea label="Comments" name="comment" />
{/* 
              <button
                type="submit"
                className="btn btn-outline-success"
                style={{ float: "right", margin:'auto', height:'40px' }}
                onClick={handleSubmitComment}
              >
                Submit
              </button> */}
              </div>




            </div>
              </div>
            </div>
         


          </div>
          <div className="row col-12">
            <strong style={{ padding: "12px" }}>Related Tickets</strong>
            <ReactTable
              filterable={true}
              showPagination={true}
              sortable={true}
              data={relatedTicketData}
              columns={ticketColumns}
              defaultSorted={[{ id: "createdOn", desc: true }]}
              style={{ height: "40vh", textAlign: 'center', width:'100%' }}
              defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
              loading={false}
            />
          </div>
          <div className="row col-12">
            <strong style={{ padding: "12px" }}>Ticket History</strong>
            <ReactTable
              filterable={true}
              showPagination={true}
              sortable={true}
              data={ticketHistoryData}
              columns={ticketHistoryColumn}
              defaultSorted={[{ id: "timestamp", desc: true }]}
              style={{ height: "40vh", textAlign: 'center', width:'100%' }}
              defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().indexOf(filter.value.toLowerCase()) > -1}
              loading={false}
            />
          </div>
        </div>
        <Modal open={this.state.createInMaintenanceModal} onClose={this.closeCreateMaintenanceModal} styles={modalStyles} center>
          <h4 className="mb-3">Create In Maintenance</h4>
          {zunpureClientAddress.state ? <div className="row">
            <div className="col-6 mb-3">
              Name
                  </div>
            <div className="col-6">
              {zunpureClientAddress.name}
            </div>
            <div className="col-6 mb-3">
              State
                  </div>
            <div className="col-6">
              {zunpureClientAddress.state}
            </div>
            <div className="col-6 mb-3">
              City
                  </div>
            <div className="col-6">
              {zunpureClientAddress.city}
            </div>
            <div className="col-6 mb-3">
              Pincode
                  </div>
            <div className="col-6">
              {zunpureClientAddress.pincode}
            </div>
            <div className="col-6 mb-3">
              Address
                  </div>
            <div className="col-6">
              {zunpureClientAddress.address}
            </div>
            <div className="col-6 mb-3">
              Responsible
                  </div>
            <div className="col-6">
              <div className="form-group">
                <div className="mb-3">
                  <Select
                    isSearchable
                    onChange={(e) => { this.handleSelectPEChange(e, 'responsiblePe') }}
                    options={responsiblePeOptions}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 mb-3">
              Purpose of visit
                  </div>
            <div className="col-6">
              <div className="form-group">
                <div className="mb-3">
                  <Select
                    isSearchable
                    onChange={(e) => { this.handleSelectPEChange(e, 'purposeOfVisit') }}
                    options={purposeOfVisitOptions}
                  />
                </div>
              </div>
            </div>
            <div className="col-6 mb-3">
              Maintenance Date
                  </div>
            <div className="col-6">
              <div className="form-group">
                <div className="mb-3">
                  <input type="date" className="form-control" name={"maintenanceDate"} onChange={(e) => this.handleSelectPEChange(e, "maintenanceDate")} />
                </div>
              </div>
            </div>
            <div className="col-12"><button className="button-edit" onClick={(e) => handleCreateInMaintenanceSubmit()}>Submit</button></div>
          </div> : "Fetching Data ....."}
        </Modal>
      </div>
    )
  }
}


function mapStateToProps(state) {
  let ticketMapping = state.fetchAllMapping && state.fetchAllMapping.IVRMapping || {}
  let { singleTicketDetails, responsibleMapping = {},ticketComments=[], ticketHistory=[]  } = state.IVRReducer
  let unicommerceMapping = state.zunsolarBulkPIInvoice && state.zunsolarBulkPIInvoice.unicommerceMapping || []
  return {
    singleTicketDetails,
    ticketMapping,
    responsibleMapping,
    ticketComments,
    ticketHistory,
    unicommerceMapping

  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchIVRMapping, fetchSingleTicket, fetchResponsiblePersonMapping,addTicketComment,fetchAllTicketComment,updateSingleTicketData,fetchTicketHistory, createTicketInMaintenance, getUnicommerceMapping }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleIVRTicket)

