import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import url, { zcommerceUrl } from '../config';
import axios from 'axios';


export const zunpulseNewOrderColumns = [
	{
		Header: '',
		accessor: 'checkbox',
		width: 50,
		filterable: true,
		sortable: false,
		columns: false,
	},
	{
		Header: 'Date',
		accessor: 'customerOrderDate',
		Cell: row => {
			return (<div>{moment(parseInt(row.value)).format('DD MMM, YYYY HH:mm:ss')}</div>)
		}
	},
	{
		Header: row => {
			let { data } = row;
			return `Order Id (${data.length})`;
		},
		accessor: 'orderId',
		Cell: row => {
			let { orderId } = row.original;
			return (<div><a href={`/zunpulse/orders/${orderId}`} target={`_blank`}>{orderId}</a></div>)
		}
	},
	{
		Header: 'Platform Order Id',
		accessor: 'platformOrderId',
	},
	{
		Header: 'Display Order Id',
		accessor: 'displayOrderId'
	},
	{
		Header: 'Product',
		accessor: 'deviceName',
	},
	{
		Header: 'Coupon Code',
		accessor:'couponName',

	},
	{
		Header: 'Type',
		accessor: 'type'
	},
	{
		Header: 'Quantity',
		accessor: 'quantity'
	},
	{
		Header: 'Name',
		accessor: 'customerName',
	},
	{
		Header: 'Payment Status',
		accessor: 'successStatus', //status
	},
	{
		Header: "Modified On",
		accessor: "lastUpdatedOn",
		Cell: row => {
			return (<div>{row.value && moment(row.value).isValid() ? moment(row.value).format('DD MMM, YYYY') : "N/A"}</div>)
		}
	},
	{
		Header: "Modified By",
		accessor: "lastUpdatedBy"
	},
	{
		Header: 'Phone',
		accessor: 'phoneNumber'
	},
	{
		Header: "Responsible",
		accessor: "responsible"
	},
	{
		Header: 'Delivery Status',
		accessor: 'status',
	},
	{
		Header: 'Ordering Date',
		accessor: 'orderDate',
		Cell: row => {
			return (<div>{row.value && moment(row.value).isValid() ? moment(row.value).format('DD MMM, YYYY') : "N/A"}</div>)
		}
	},
	{
		Header: 'Shipping Date',
		accessor: 'shippingDate',
		Cell: row => {
			return (<div>{row.value && moment(row.value).isValid() ? moment(row.value).format('DD MMM, YYYY') : "N/A"}</div>)
		}
	},
	{
		Header: 'Delivery Date',
		accessor: 'deliveryDate',
		Cell: row => {
			return (<div>{row.value && moment(row.value).isValid() ? moment(row.value).format('DD MMM, YYYY') : "N/A"}</div>)
		}
	},
	{
		Header: 'Next Action Date',
		accessor: 'nextActionDate',
		Cell: row => {
			return (<div>{row.value && moment(+row.value).isValid() ? moment(+row.value).format('DD MMM, YYYY') : "N/A"}</div>)
		}
	},
	{
		Header: 'Return Date',
		accessor: 'returnDate',
		Cell: row => {
			return (<div>{row.value && moment(row.value).isValid() ? moment(row.value).format('DD MMM, YYYY') : "N/A"}</div>)
		}
	},
	{
		Header: 'Email',
		accessor: 'email',
	},
	{
		Header: 'Shipment Location',
		accessor: 'shipmentLocationView'
	},
	{
		Header: 'State',
		accessor: 'state' //deliveryAddress.state
	},
	{
		Header: 'City',
		accessor: 'city' //deliveryAddress.city
	},
	{
		Header: 'Source',
		accessor: 'source'
	},
	{
		Header: "Tracking id",
		accessor: "trackingId"
	},
	{
		Header: "Stage",
		accessor: "stage"
	},
	{
		Header: "High Value",
		accessor: "highValue"
	},
	{
		Header: "Key",
		accessor: "id",
		show: false
	}
];

export const zunpulseOrderColumns = [
	{
		Header: '',
		accessor: 'checkbox',
		width: 50,
		filterable: true,
		sortable: false,
		columns: false,
	},
	{
		Header: 'Date',
		accessor: 'customerOrderDate',
		Cell: row => {
			return (<div>{moment(parseInt(row.value)).format('DD MMM, YYYY HH:mm:ss')}</div>)
		}
	},
	{
		Header: row => {
			let { data } = row;
			return `Order Id (${data.length})`;
		},
		accessor: 'orderId',
		Cell: row => {
			let { orderId, productId, itemId, id } = row.original;
			return (<div><a href={`/zunpulse/orders/new/${id}`} target={`_blank`}>{`${orderId}_${productId}_${itemId}`}</a></div>)
		}
	},
	{
		Header: 'Platform Order Id',
		accessor: 'platformOrderId',
	},
	{
		Header: 'Product',
		accessor: 'deviceName',
	},
	{
		Header: 'Type',
		accessor: 'type'
	},
	{
		Header: 'Name',
		accessor: 'customerName',
	},
	{
		Header: 'Payment Status',
		accessor: 'paymentStatus',
	},
	{
		Header: "Modified On",
		accessor: "lastUpdatedOn",
		Cell: row => {
			return (<div>{row.value && moment(row.value).isValid() ? moment(row.value).format('DD MMM, YYYY') : "N/A"}</div>)
		}
	},
	{
		Header: "Modified By",
		accessor: "lastUpdatedBy"
	},
	{
		Header: 'Phone',
		accessor: 'phoneNumber'
	},
	{
		Header: "Responsible",
		accessor: "responsible"
	},
	{
		Header: 'Delivery Status',
		accessor: 'status',
	},
	{
		Header: 'Ordering Date',
		accessor: 'orderDate',
		Cell: row => {
			return (<div>{row.value && moment(row.value).isValid() ? moment(row.value).format('DD MMM, YYYY') : "N/A"}</div>)
		}
	},
	{
		Header: 'Shipping Date',
		accessor: 'shippingDate',
		Cell: row => {
			return (<div>{row.value && moment(row.value).isValid() ? moment(row.value).format('DD MMM, YYYY') : "N/A"}</div>)
		}
	},
	{
		Header: 'Delivery Date',
		accessor: 'deliveryDate',
		Cell: row => {
			return (<div>{row.value && moment(row.value).isValid() ? moment(row.value).format('DD MMM, YYYY') : "N/A"}</div>)
		}
	},
	{
		Header: 'Next Action Date',
		accessor: 'nextActionDate',
		Cell: row => {
			return (<div>{row.value && moment(row.value).isValid() ? moment(row.value).format('DD MMM, YYYY') : "N/A"}</div>)
		}
	},
	{
		Header: 'Email',
		accessor: 'email',
	},
	{
		Header: 'State',
		accessor: 'deliveryAddress.state'
	},
	{
		Header: 'City',
		accessor: 'deliveryAddress.city'
	},
	{
		Header: 'Source',
		accessor: 'source'
	},
	{
		Header: "Tracking id",
		accessor: "trackingId"
	},
	{
		Header: "Stage",
		accessor: "stage"
	},
	{
		Header: "High Value",
		accessor: "highValue"
	},
	{
		Header: "Key",
		accessor: "id",
		show: false
	}
];

export const zunpulseLeadTransferColumns = [
	{
		Header: "Name",
		accessor: "Name",
	},
	{
		Header: "New",
		accessor: "New",
	},
	{
		Header: "Not Interesed",
		accessor: "Not Interested",
	},
	{
		Header: "Interested",
		accessor: "Interested",
	},
	{
		Header: "Not Connected",
		accessor: "Not Connected"
	},
	{
		Header: "Closing",
		accessor: "Closing",
	},
	{
		Header: "Sold",
		accessor: "Sold",
	},
	{
		Header: "Submit",
	}
]


export const inventoryColumns = [
	{
		Header: "PO Date",
		accessor: "date",
	},
	{
		Header: "PO No",
		accessor: "supplyOrderId",
	},
	{
		Header: "Lot No",
		accessor: "lotNo",
	},
	{
		Header: "Category",
		accessor: "category",
	},
	{
		Header: "Sub Category",
		accessor: "subCategory"
	},
	{
		Header: "Invoice No",
		accessor: "invoiceNo",
	},
	{
		Header: "Invoice Date",
		accessor: "preferenceDate",
	},
	{
		Header: "Order Qty",
		accessor: "orderQty",
	},
	{
		Header: "Recieved Qty",
		accessor: "inStock",
	},
	{
		Header: "Unit Price",
		accessor: "unitPrice",
	},
	{
		Header: "GST",
		accessor: "gst",
	},
	{
		Header: "Freight",
		accessor: "freight",
	},
	{
		Header: "Freight Tax",
		accessor: "freightTax"
	},
	{
		Header: "Total",
		accessor: "total",
	}
]


export const comboColumns = [
	{
		Header: "Combo Id",
		accessor: "comboId",
	},
	{
		Header: "Active",
		accessor: "active",
	},
	{
		Header: "Product 1 C",
		accessor: "product1Category",
	},
	{
		Header: "Product 1 SC",
		accessor: "product1SubCategory",
	},
	{
		Header: "Product 1 Qty",
		accessor: "product1Qty",
	},
	{
		Header: "Product 2 C",
		accessor: "product2Category",
	},
	{
		Header: "Product 2 SC",
		accessor: "product2SubCategory",
	},
	{
		Header: "Product 2 Qty",
		accessor: "product2Qty",
	},
	{
		Header: "Product 3 C",
		accessor: "product3Category",
	},
	{
		Header: "Product 3 SC",
		accessor: "product3SubCategory",
	},
	{
		Header: "Product 3 Qty",
		accessor: "product3Qty",
	},
	{
		Header: "Product 4 C",
		accessor: "product4Category",
	},
	{
		Header: "Product 4 SC",
		accessor: "product4SubCategory",
	},
	{
		Header: "Product 4 Qty",
		accessor: "product4Qty",
	},
	{
		Header: "Product 5 C",
		accessor: "product5Category",
	},
	{
		Header: "Product 5 SC",
		accessor: "product5SubCategory",
	},
	{
		Header: "Product 5 Qty",
		accessor: "product5Qty",
	},
	{
		Header: "Product 6 C",
		accessor: "product6Category",
	},
	{
		Header: "Product 6 SC",
		accessor: "product6SubCategory",
	},
	{
		Header: "Product 6 Qty",
		accessor: "product6Qty",
	},
	{
		Header: "Product 7 C",
		accessor: "product7Category",
	},
	{
		Header: "Product 7 SC",
		accessor: "product7SubCategory",
	},
	{
		Header: "Product 7 Qty",
		accessor: "product7Qty",
	},
	{
		Header: "Product 8 C",
		accessor: "product8Category",
	},
	{
		Header: "Product 8 SC",
		accessor: "product8SubCategory",
	},
	{
		Header: "Product 8 Qty",
		accessor: "product8Qty",
	}
]

export const bulkInvoiceColumns = [
	{Header: 'Id', accessor: 'id'},
	{Header: 'Dealer Id', accessor: 'dealerId'},
	{Header: 'HsnSac', accessor: 'hsnSac'},
	{Header: 'Product', accessor: 'product'},
	{Header: 'Pid', accessor: 'pid'},
	{Header: 'Description', accessor: 'description'},
	{Header: 'Quantity', 
	accessor: 'qty'},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.rate && !isNaN(b.rate) ? parseInt(b.rate) : 0), 0);
			return `Rate (${total})`;
		},
		accessor: 'rate'},
	{
		Header: row => {
		let { data } = row;
		let total = data.reduce((a, b) => a + (b.tax && !isNaN(b.tax) ? parseInt(b.tax) : 0), 0);
		return `Tax (${total})`;
			},
	accessor: 'tax'},
	{ Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.amount && !isNaN(b.amount) ? parseInt(b.amount) : 0), 0);
			return `Amount (${total})`;
		},
	 accessor: 'amount'},
	{Header: 'Platform', accessor: 'platform'},
	{Header: 'Date', accessor: 'date'},
	{Header: 'Transaction Type', accessor: 'transactionType'},
	{Header: 'Invoice Number', accessor: 'invoiceNumber'},
	{Header: 'Name', accessor: 'name'},
	{Header: 'Memo Description', accessor: 'memoDescription'},
	{Header: 'Account', accessor: 'account'},
	{Header: 'Split', accessor: 'split'},


	// {Header: 'Invoice Number', accessor: 'invoiceNumber'},
	// {Header: 'invoiceDate', accessor: 'invoiceDate'},
	// {Header: 'transactionType', accessor: 'transactionType'},
	// {Header: 'orderId', accessor: 'orderId'},
	// {Header: 'shipmentId', accessor: 'shipmentId'},
	// {Header: 'shipmentDate', accessor: 'shipmentDate'},
	// {Header: 'orderDate', accessor: 'orderDate'},
	// {Header: 'shipmentItemId', accessor: 'shipmentItemId'},
	// {Header: 'quantity', accessor: 'quantity'},
	// {Header: 'itemDescription', accessor: 'itemDescription'},
	// {Header: 'asin', accessor: 'asin'},
	// {Header: 'sku', accessor: 'sku'},
	// {Header: 'productTaxCode', accessor: 'productTaxCode'},
	// {Header: 'billFromCity', accessor: 'billFromCity'},
	// {Header: 'billFromState', accessor: 'billFromState'},
	// {Header: 'billFromCountry', accessor: 'billFromCountry'},
	// {Header: 'billFromPostalCode', accessor: 'billFromPostalCode'},
	// {Header: 'shipFromCity', accessor: 'shipFromCity'},
	// {Header: 'shipFromState', accessor: 'shipFromState'},
	// {Header: 'shipFromCountry', accessor: 'shipFromCountry'},
	// {Header: 'shipFromPostalCode', accessor: 'shipFromPostalCode'},
	// {Header: 'shipToCity', accessor: 'shipToCity'},
	// {Header: 'shipToState', accessor: 'shipToState'},
	// {Header: 'shipToCountry', accessor: 'shipToCountry'},
	// {Header: 'shipToPostalCode', accessor: 'shipToPostalCode'},
	// {   Header: row => {
	// 	let { data } = row;
	// 	let total = data.reduce((a, b) => a + (b.invoiceAmount && !isNaN(b.invoiceAmount) ? parseInt(b.invoiceAmount) : 0), 0);
	// 	return `Invoice Amount (${total})`;
	// },
	// 	accessor: 'invoiceAmount'},
	// { Header: row => {
	// 	let { data } = row;
	// 	let total = data.reduce((a, b) => a + (b.taxExclusiveGross && !isNaN(b.taxExclusiveGross) ? parseInt(b.taxExclusiveGross) : 0), 0);
	// 	return ` Tax Exclusive Gross (${total})`;
	// 		},
	//  	accessor: 'taxExclusiveGross'},
	// {	Header: row => {
	// 	let { data } = row;
	// 	let total = data.reduce((a, b) => a + (b.totalTaxAmount && !isNaN(b.totalTaxAmount) ? parseInt(b.totalTaxAmount) : 0), 0);
	// 	return ` Total Tax Amount (${total})`;
	// 		},
	// 	 accessor: 'totalTaxAmount'},
	// {Header: 'cgstRate', accessor: 'cgstRate'},
	// {Header: 'sgstRate', accessor: 'sgstRate'},
	// {Header: 'utgstRate', accessor: 'utgstRate'},
	// {Header: 'igstRate', accessor: 'igstRate'},
	// {Header: 'compensatoryCessRate', accessor: 'compensatoryCessRate'},
	// {Header: 'principalAmount', accessor: 'principalAmount'},
	// {Header: 'principalAmountBasis', accessor: 'principalAmountBasis'},
	// {Header: 'cgstTax', accessor: 'cgstTax'},
	// {Header: 'sgstTax', accessor: 'sgstTax'},
	// {Header: 'utgstTax', accessor: 'utgstTax'},
	// {Header: 'igstTax', accessor: 'igstTax'},
	// {Header: 'compensatoryCessTax', accessor: 'compensatoryCessTax'},
	// {Header: 'shippingAmount', accessor: 'shippingAmount'},
	// {Header: 'shippingAmountBasis', accessor: 'shippingAmountBasis'},
	// {Header: 'shippingCgstTax', accessor: 'shippingCgstTax'},
	// {Header: 'shippingSgstTax', accessor: 'shippingSgstTax'},
	// {Header: 'shippingUtgstTax', accessor: 'shippingUtgstTax'},
	// {Header: 'shippingIgstTax', accessor: 'shippingIgstTax'},
	// {Header: 'shippingCessTax', accessor: 'shippingCessTax'},
	// {Header: 'giftWrapAmount', accessor: 'giftWrapAmount'},
	// {Header: 'giftWrapAmountBasis', accessor: 'giftWrapAmountBasis'},
	// {Header: 'giftWrapCgstTax', accessor: 'giftWrapCgstTax'},
	// {Header: 'giftWrapSgstTax', accessor: 'giftWrapSgstTax'},
	// {Header: 'giftWrapUtgstTax', accessor: 'giftWrapUtgstTax'},
	// {Header: 'giftWrapIgstTax', accessor: 'giftWrapIgstTax'},
	// {Header: 'giftWrapCompensatoryCessTax', accessor: 'giftWrapCompensatoryCessTax'},
	// {Header: 'itemPromoDiscount', accessor: 'itemPromoDiscount'},
	// {Header: 'itemPromoDiscountBasis', accessor: 'itemPromoDiscountBasis'},
	// {Header: 'itemPromoTax', accessor: 'itemPromoTax'},
	// {Header: 'shippingPromoDiscount', accessor: 'shippingPromoDiscount'},
	// {Header: 'shippingPromoDiscountBasis', accessor: 'shippingPromoDiscountBasis'},
	// {Header: 'shippingPromoTax', accessor: 'shippingPromoTax'},
	// {Header: 'giftWrapPromoDiscount', accessor: 'giftWrapPromoDiscount'},
	// {Header: 'giftWrapPromoDiscountBasis', accessor: 'giftWrapPromoDiscountBasis'},
	// {Header: 'giftWrapPromoTax', accessor: 'giftWrapPromoTax'},
	// {Header: 'tcsCgstRate', accessor: 'tcsCgstRate'},
	// {Header: 'tcsCgstAmount', accessor: 'tcsCgstAmount'},
	// {Header: 'tcsSgstRate', accessor: 'tcsSgstRate'},
	// {Header: 'tcsSgstAmount', accessor: 'tcsSgstAmount'},
	// {Header: 'tcsUtgstRate', accessor: 'tcsUtgstRate'},
	// {Header: 'tcsUtgstAmount', accessor: 'tcsUtgstAmount'},
	// {Header: 'tcsIgstRate', accessor: 'tcsIgstRate'},
	// {Header: 'tcsIgstAmount', accessor: 'tcsIgstAmount'},
	// {Header: 'warehouseId', accessor: 'warehouseId'},
	// {Header: 'fulfillmentChannel', accessor: 'fulfillmentChannel'},
	// {Header: 'paymentMethodCode', accessor: 'paymentMethodCode'},
	// {Header: 'billToCity', accessor: 'billToCity'},
	// {Header: 'billToState', accessor: 'billToState'},
	// {Header: 'billToCountry', accessor: 'billToCountry'},
	// {Header: 'billToPostalcode', accessor: 'billToPostalcode'},
	// {Header: 'customerBillToGstid', accessor: 'customerBillToGstid'},
	// {Header: 'customerShipToGstid', accessor: 'customerShipToGstid'},
	// {Header: 'buyerName', accessor: 'buyerName'},
	// {Header: 'creditNoteNo', accessor: 'creditNoteNo'},
	// {Header: 'creditNoteDate', accessor: 'creditNoteDate'},
	// {Header: 'irnNumber', accessor: 'irnNumber'},
	// {Header: 'irnFilingStatus', accessor: 'irnFilingStatus'},
	// {Header: 'irnDate', accessor: 'irnDate'},
	// {Header: 'irnErrorCode', accessor: 'irnErrorCode'},
	// {Header: 'createdAt', accessor: 'createdAt'},
	// {Header: 'date', accessor: 'date'},
	// {Header: 'type', accessor: 'type'},
	// {Header: 'dealerId', accessor: 'dealerId'},
	// {Header: 'shippingGstTax', accessor: 'shippingGstTax'}
	


]

export const orderColumns = [
	{
		Header: "Months",
		accessor: "month",
		// Cell: row => moment(row.value,"YYYY-MM-DD").format("YYYYMM")
	},
	{
		Header: "Date",
		accessor: "date",
	},
	{
		Header: "Platform",
		accessor: "platform",
	},
	{
		Header: row => {
			let { data } = row;
			let orderIdSet = new Set();
			data.map((obj) => {
				if (obj.orderId) {
					orderIdSet.add(obj.orderId)
				}
			});
			return `Order Id ${orderIdSet.size}`;
		},
		csvHeader: "Order Id",
		accessor: "orderId",
		Cell: row => (
			<div>
				<Link to={"/zunsolar/order/" + encodeURIComponent(row.value)} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/zunsolar/order/" + encodeURIComponent(row.value)); }}>{row.value}</Link>
			</div>
		),
	},
	{
		Header: row => {
			let { data } = row;
			let orderIdSet = new Set();
			data.map((obj) => {
				if (obj.displayOrderId) {
					orderIdSet.add(obj.displayOrderId)
				}
			});
			return `Display Order Id ${orderIdSet.size}`;
		},
		csvHeader: "Display Order Id",
		accessor: "displayOrderId",
	},
	{
		Header: row => {
			let { data } = row;
			return `Suborder Id ${data.length}`;
		},
		csvHeader: "Suborder Id",
		accessor: "subOrderId",
	},
	{
		Header: "Category",
		accessor: "category",
	},
	{
		Header: "Sub Category",
		accessor: "subCategory",
	},
	{
		Header: "Product Name",
		accessor: "productName",
	},
	{
		Header: "Variant Type",
		accessor: "variantType",
		csvHeader: "Variant Type"
	},
	{
		Header: "Payment Status",
		accessor: "paymentStatus",
		csvHeader: "Payment Status"
		
	},
	{
		Header: 'Coupon',
		accessor: 'coupon'
	},
	{
		Header: 'Coupon Discount',
		accessor: 'couponDiscount'
	},
	{
		Header: 'UTM',
		accessor: 'utm'
	},
	{
		Header: "Status",
		accessor: "status",
	},
	{
		Header: "Shipping Partner",
		accessor: "shippingPartner",
	},
	{
		Header: "Pick Up Date",
		accessor: "pickUpDate",
	},
	{
		Header: "Delivery Date",
		accessor: "deliveryDate"
	},
	// {
	// 	Header: "Claim Status",
	// 	accessor: "claimStatus"
	// },
	// {
	// 	Header: "Loss/Damage Status",
	// 	accessor: "lossdamageStatus"
	// },
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.onlinePrice && !isNaN(b.onlinePrice) ? parseInt(b.onlinePrice) : 0), 0);
			return `Online Price (${total.toLocaleString("en-IN")})`;
		},
		csvHeader: "Online Price",
		accessor: "onlinePrice",
	},
	{
		Header: "Platform Fees",
		accessor: "platformFees",
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.shippingFees && !isNaN(b.shippingFees) ? parseInt(b.shippingFees) : 0), 0);
			return `Shipping Fees (${total.toLocaleString("en-IN")})`;
		},
		csvHeader: "Shipping Fees",
		accessor: "shippingFees"
	},
	// {
	// 	Header: "Tracking ID",
	// 	accessor: "bankTransfer"
	// },
	{
		Header: "Tracking Id",
		accessor: "trackingNumber"
	},
	{
		Header: "Shipment Location",
		accessor: "shipmentLocationView",
		csvHeader: "shipmentLocationView"
	},
	{
		Header: "Claim Received",
		accessor: "claimReceived"
	},
	{
		Header: "Claim Status",
		accessor: "claimStatus",
		show: false
	},
	{
		Header: "Cancellation Date",
		accessor: "cancellationDate",
		show: false
	},
	{
		Header: "City",
		accessor: "city",
		// show: false
	},
	{
		Header: "State",
		accessor: "state",
		// show: false
	},
	{
		Header: "Pincode",
		accessor: "pincode",
		show: false
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (b.invoiceValue && !isNaN(b.invoiceValue) ? parseInt(b.invoiceValue) : 0), 0);
			return `Invoice Value (${total.toLocaleString("en-IN")})`;
		},
		csvHeader: 'Invoice Value',
		accessor: 'invoiceValue'
	},
	{
		Header: 'Invoice Date',
		accessor: 'invoiceDate'
	},
	{
		Header: 'Partner Id ',
		accessor: 'partnerId'
	},
	{
		Header: "Partner GST",
		accessor: "partnerGst",
		show: false
	},
	{
		Header: 'Invoice Number',
		accessor: 'invoiceNumber'
	},
	{
		Header: 'Invoice',
		accessor: 'orderInvoice'
	},
	{
		Header: "Loss/Damage Status",
		accessor: "lossdamageStatus"
	},
	{
		Header: "Warehouse Charges",
		accessor: "warehouseCharges",
		show: false
	},
	{
		Header: "Packaging Charges",
		accessor: "packagingCharges",
		show: false
	},
	{
		Header: "Return (Before Delivery) Date",
		accessor: "returnBeforeDeliveryDate",
		show: false
	},
	{
		Header: "Return (After Delivery) Date",
		accessor: "returnAfterDeliveryDate",
		show: false
	},
	{
		Header: "Return Initiated",
		accessor: "returnInitiated",
		show: false
	},
	{
		Header: "Return Receipt Date",
		accessor: "returnReceiptDate",
		show: false
	},
	{
		Header: "Lot Number",
		accessor: "lotNo",
		show: false
	},
	{
		Header: "Transaction Id",
		accessor: "transactionId",
		show: false
	},
	{
		Header: "Advanced Recieved",
		accessor: "advancedRecieved",
		show: false
	},
	{
		Header: "Cancellation Date",
		accessor: "cancellationDate",
		show: false
	},
	{
		Header: "Name",
		accessor: "name",
		// show: false
	},
	{
		Header: "Address",
		accessor: "address",
		show: false
	},
	{
		Header: "Payment Mode",
		accessor: "paymentMode",
		show: false,
	},
	{
		Header: "Pincode",
		accessor: "pincode",
		show: false
	},
	{
		Header: "Brand",
		accessor: "brand"
	},
	{
		Header: "Wattage",
		accessor: "wattage"
	},
	{
		Header: "Return Reason",
		accessor: "returnReason"
	},
	{
		Header: "Parent Order Id",
		accessor: "parentOrderId"
	},
	{
		Header: "Parent Sub-Order Id",
		accessor: "parentSubOrderId"
	},
	{
		Header: "UNI Order Status",
		accessor: "unicomerceOrderStatus"
	},
	{
		Header: "UNI Item Status",
		accessor: "unicomerceItemLevelStatus"
	},
	{
		Header: "UNI Shipping Status",
		accessor: "unicomerceshippingPackageStatus"
	},
	{
		Header: "UNI Return Status",
		accessor: "unicomerceReturnStatus"
	},
	{
		Header: "UNI Cancellation Reason",
		accessor: "unicomerceCancellationReason"
	},
	{
		Header: "Created On",
		accessor: "createdOn"
	},
	{
		Header: "Promocash discount",
		accessor: "promoCash",
		Cell: row => {
			if(row && row.original && row.original.paymentStatus && ["success", "failure"].includes(row.original.paymentStatus.toLowerCase())) {
				return row.value;
			}
			return "";
		}
	},
	{
		Header: "Order Cancelled",
		accessor: "orderCancelled"
	},
	{
		Header: "Cancellation Reason",
		accessor: "cancellationReason"
	},
	{
		Header: "Other Cancellation Reason",
		accessor: "otherCancellationReason"
	},
	{
		Header: "Price without GST",
		accessor: "priceProductLevel",
	},
	{
		Header: "Discount without GST",
		accessor: "discountProductLevel",
	},
	{
		Header: "GST Rate",
		accessor: "gstRateProductLevel",
	},
	{
		Header: "SKU Code",
		accessor: "unicomerceItemSkuCode",
	},
	{
		Header: "PID",
		accessor: "pID",
	}
]

export const invoiceColumns = [
	// {
	// 	Header: "Sr. No",
	// 	accessor: "srno",
	// },
	{
		Header: "Invoice Number",
		accessor: "invoiceNumber",
	},
	{
		Header: "Order Id",
		accessor: "orderId",
	},
	{
		Header: "Invoice Type",
		accessor: "invoiceType",
	},
	{
		Header: "Amount",
		accessor: "amount"
	},
	{
		Header: "Date",
		accessor: "date",
		Cell: row => {
			return moment(row.value).format("DD MMM, YYYY")
		}
	},
	{
		Header: "Products",
		accessor: "products"
	},
	{
		Header: "Value before GST",
		accessor: "subTotal"
	},
	{
		Header: "GST Value",
		accessor: "totalGst"
	},
	{
		Header: "Total Amount",
		accessor: "totalAmount"
	},
	{
		Header: "Quantity",
		accessor: "quantity"
	},
	{
		Header: "Place of supply",
		accessor: "placeOfSupply"
	},
	{
		Header: "Product Details",
		accessor: "productDetails",
		Cell: row => {
			return row.value ? <div>
				{
					row.value.split('\n').map(productRow => <div>{productRow}</div>)
				}
			</div> : ""
		},
		width: 250
	},

]

export const salesSummaryConfig = [
	{
		Header: row => {
			let { data } = row;
			return `Months (${data.length})`;
		},
		accessor: "month"
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.Amazon) ? parseInt(b.Amazon) : 0), 0);
			return `Amazon (${total.toLocaleString("en-IN")})`;
		},
		accessor: "Amazon",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.Flipkart) ? parseInt(b.Flipkart) : 0), 0);
			return `Flipkart (${total.toLocaleString("en-IN")})`;
		},
		accessor: "Flipkart",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.Snapdeal) ? parseInt(b.Snapdeal) : 0), 0);
			return `Snapdeal (${total.toLocaleString("en-IN")})`;
		},
		accessor: "Snapdeal",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.Zunsolar) ? parseInt(b.Zunsolar) : 0), 0);
			return `Zunsolar (${total.toLocaleString("en-IN")})`;
		},
		accessor: "Zunsolar",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.app) ? parseInt(b.app) : 0), 0);
			return `app (${total.toLocaleString("en-IN")})`;
		},
		accessor: "app",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.Dealer) ? parseInt(b.Dealer) : 0), 0);
			return `Dealer (${total.toLocaleString("en-IN")})`;
		},
		accessor: "Dealer",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.Distributor) ? parseInt(b.Distributor) : 0), 0);
			return `Distributor (${total.toLocaleString("en-IN")})`;
		},
		accessor: "Distributor",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b["Industry Buying"]) ? parseInt(b["Industry Buying"]) : 0), 0);
			return `Industry Buying (${total.toLocaleString("en-IN")})`
		},
		accessor: "Industry Buying",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.Moglix) ? parseInt(b.Moglix) : 0), 0);
			return `Moglix (${total.toLocaleString("en-IN")})`
		},
		accessor: "Moglix",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	// {
	// 	Header: row => {
	// 		let { data } = row;
	// 		let total = data.reduce((a, b) => a + (!isNaN(b.Indiamart) ? parseInt(b.Indiamart) : 0), 0);
	// 		return `Indiamart (${total})`
	// 	},
	// 	accessor: "Indiamart"
	// },
	// {
	// 	Header: row => {
	// 		let { data } = row;
	// 		let total = data.reduce((a, b) => a + (!isNaN(b.B2B) ? parseInt(b.B2B) : 0), 0);
	// 		return `B2B (${total})`
	// 	},
	// 	accessor: "B2B"
	// },
	// {
	// 	Header: row => {
	// 		let { data } = row;
	// 		let total = data.reduce((a, b) => a + (!isNaN(b.Direct) ? parseInt(b.Direct) : 0), 0);
	// 		return `Direct (${total})`
	// 	},
	// 	accessor: "Direct"
	// },
	// {
	// 	Header: row => {
	// 		let { data } = row;
	// 		let total = data.reduce((a, b) => a + (!isNaN(b.NGOs) ? parseInt(b.NGOs) : 0), 0);
	// 		return `NGOs (${total})`
	// 	},
	// 	accessor: "NGOs"
	// },
	// {
	// 	Header: row => {
	// 		let { data } = row;
	// 		let total = data.reduce((a, b) => a + (!isNaN(b.Testing) ? parseInt(b.Testing) : 0), 0);
	// 		return `Testing (${total})`;
	// 	},
	// 	accessor: "Testing",
	// },
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.total) ? parseInt(b.total) : 0), 0);
			return `Total (${total.toLocaleString("en-IN")})`;
		},
		accessor: "total",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.onlineTotal) ? parseInt(b.onlineTotal) : 0), 0);
			return `Online Channel(${total.toLocaleString("en-IN")})`;
		},
		accessor: "onlineTotal",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.offlineTotal) ? parseInt(b.offlineTotal) : 0), 0);
			return `Offline Channel(${total.toLocaleString("en-IN")})`;
		},
		accessor: "offlineTotal",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	}
]


export const shippingSummaryConfig = [
	{
		Header: row => {
			let { data } = row;
			return `Months (${data.length})`;
		},
		accessor: "month"
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.Delivered) ? parseInt(b.Delivered) : 0), 0);
			return `Delivered (${total.toLocaleString("en-IN")})`;
		},
		accessor: "Delivered",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b["In Transit"]) ? parseInt(b["In Transit"]) : 0), 0);
			return `In Transit (${total.toLocaleString("en-IN")})`;
		},
		accessor: "In Transit",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b["Not shipped"]) ? parseInt(b["Not shipped"]) : 0), 0);
			return `Not shipped (${total.toLocaleString("en-IN")})`;
		},
		accessor: "Not shipped",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b["Cancelled"]) ? parseInt(b["Cancelled"]) : 0), 0);
			return `Cancelled (${total.toLocaleString("en-IN")})`;
		},
		accessor: "Cancelled",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b["Returned (Before Delivery)"]) ? parseInt(b["Returned (Before Delivery)"]) : 0), 0);
			return `Returned (Before Delivery) (${total.toLocaleString("en-IN")})`;
		},
		accessor: "Returned (Before Delivery)",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b["Returned (After Delivery)"]) ? parseInt(b["Returned (After Delivery)"]) : 0), 0);
			return `Returned (After Delivery) (${total.toLocaleString("en-IN")})`;
		},
		accessor: "Returned (After Delivery)",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	// {
	// 	Header: row => {
	// 		let { data } = row;
	// 		let total = data.reduce((a, b) => a + (!isNaN(b["Pick-up scheduled"]) ? parseInt(b["Pick-up scheduled"]) : 0), 0);
	// 		return `Pick-up scheduled (${total})`;
	// 	},
	// 	accessor: "Pick-up scheduled",
	// },
	// {
	// 	Header: row => {
	// 		let { data } = row;
	// 		let total = data.reduce((a, b) => a + (!isNaN(b["Truncated"]) ? parseInt(b["Truncated"]) : 0), 0);
	// 		return `Truncated (${total})`;
	// 	},
	// 	accessor: "Truncated",
	// },

	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b["Returning (Before Delivery)"]) ? parseInt(b["Returning (Before Delivery)"]) : 0), 0);
			return `Returning (Before Delivery) (${total.toLocaleString("en-IN")})`;
		},
		accessor: "Returning (Before Delivery)",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b["Returning (After Delivery)"]) ? parseInt(b["Returning (After Delivery)"]) : 0), 0);
			return `Returning (After Delivery) (${total.toLocaleString("en-IN")})`;
		},
		accessor: "Returning (After Delivery)",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	// {
	// 	Header: row => {
	// 		let { data } = row;
	// 		let total = data.reduce((a, b) => a + (!isNaN(b["Lost/Damaged - Claim Pending"]) ? parseInt(b["Lost/Damaged - Claim Pending"]) : 0), 0);
	// 		return `Lost/Damaged - Claim Pending (${total.toLocaleString("en-IN")})`;
	// 	},
	// 	accessor: "Lost/Damaged - Claim Pending",
	// 	Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	// },
	// {
	// 	Header: row => {
	// 		let { data } = row;
	// 		let total = data.reduce((a, b) => a + (!isNaN(b["Lost/Damaged - Claim Received"]) ? parseInt(b["Lost/Damaged - Claim Received"]) : 0), 0);
	// 		return `Lost/Damaged - Claim Received (${total.toLocaleString("en-IN")})`;
	// 	},
	// 	accessor: "Lost/Damaged - Claim Received",
	// 	Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	// },
	// {
	// 	Header: row => {
	// 		let { data } = row;
	// 		let total = data.reduce((a, b) => a + (!isNaN(b["Lost/Damaged - Claim Rejected"]) ? parseInt(b["Lost/Damaged - Claim Rejected"]) : 0), 0);
	// 		return `Lost/Damaged - Claim Rejected (${total.toLocaleString("en-IN")})`;
	// 	},
	// 	accessor: "Lost/Damaged - Claim Rejected",
	// 	Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	// },
	// {
	// 	Header: row => {
	// 		let { data } = row;
	// 		let total = data.reduce((a, b) => a + (!isNaN(b["Booked"]) ? parseInt(b["Booked"]) : 0), 0);
	// 		return `Booked (${total})`;
	// 	},
	// 	accessor: "Booked",
	// },
	// {
	// 	Header: row => {
	// 		let { data } = row;
	// 		let total = data.reduce((a, b) => a + (!isNaN(b["Invoiced"]) ? parseInt(b["Invoiced"]) : 0), 0);
	// 		return `Invoiced (${total})`;
	// 	},
	// 	accessor: "Invoiced",
	// },
	// {
	// 	Header: row => {
	// 		let { data } = row;
	// 		let total = data.reduce((a, b) => a + (!isNaN(b["Out of stock"]) ? parseInt(b["Out of stock"]) : 0), 0);
	// 		return `Out of stock (${total})`;
	// 	},
	// 	accessor: "Out of stock",
	// },
	// {
	// 	Header: row => {
	// 		let { data } = row;
	// 		let total = data.reduce((a, b) => a + (!isNaN(b["Pre-Booked"]) ? parseInt(b["Pre-Booked"]) : 0), 0);
	// 		return `Pre-Booked (${total})`;
	// 	},
	// 	accessor: "Pre-Booked",
	// },
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b["count"]) ? parseInt(b["count"]) : 0), 0);
			return `Total (${total.toLocaleString("en-IN")})`;
		},
		accessor: "count",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
]


export const orderValueConfig = [
	{
		Header: row => {
			let { data } = row;
			return `Invoice Months (${data.length})`;
		},
		accessor: "month"
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + ((!isNaN(b.invoiceValue) && (b.invoiceValue != null)) ? parseInt(b.invoiceValue) : 0), 0);
			return `Invoice Value (${total.toLocaleString("en-IN")})`;
		},
		accessor: "invoiceValue",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + ((!isNaN(b.advancedRecieved) && (b.advancedRecieved != null)) ? parseInt(b.advancedRecieved) : 0), 0);
			return `Advanced Recieved (${total.toLocaleString("en-IN")})`;
		},
		accessor: "advancedRecieved",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
]

export const subOrderValueConfig = [
	{
		Header: row => {
			let { data } = row;
			return `Months (${data.length})`;
		},
		accessor: "month"
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.count) ? parseInt(b.count) : 0), 0);
			return `Count (${total.toLocaleString("en-IN")})`;
		},
		accessor: "count",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.sum) ? parseInt(b.sum) : 0), 0);
			return `Booked Value (${total.toLocaleString("en-IN")})`;
		},
		accessor: "sum",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: "Average",
		accessor: "average",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	}
]

export const orderValueConfigZunpulse = [
	{
		Header: row => {
			let { data } = row;
			return `Months (${data.length})`;
		},
		accessor: "month"
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.totalProducts) ? parseInt(b.totalProducts) : 0), 0);
			return `Products (${total.toLocaleString("en-IN")})`;
		},
		accessor: "totalProducts",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.count) ? parseInt(b.count) : 0), 0);
			return `Count (${total.toLocaleString("en-IN")})`;
		},
		accessor: "count",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.sum) ? parseInt(b.sum) : 0), 0);
			return `Sum (${total.toLocaleString("en-IN")})`;
		},
		accessor: "sum",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: "Average",
		accessor: "average",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	}
]


export const lastTenDaysSummaryConfig = [
	{
		Header: "Date",
		accessor: "date"
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.Amazon) ? parseInt(b.Amazon) : 0), 0);
			return `Amazon (${total.toLocaleString("en-IN")})`;
		},
		accessor: "Amazon",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.Flipkart) ? parseInt(b.Flipkart) : 0), 0);
			return `Flipkart (${total.toLocaleString("en-IN")})`;
		},
		accessor: "Flipkart",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.Snapdeal) ? parseInt(b.Snapdeal) : 0), 0);
			return `Snapdeal (${total.toLocaleString("en-IN")})`;
		},
		accessor: "Snapdeal",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.Zunsolar) ? parseInt(b.Zunsolar) : 0), 0);
			return `Zunsolar (${total.toLocaleString("en-IN")})`;
		},
		accessor: "Zunsolar",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.app) ? parseInt(b.app) : 0), 0);
			return `app (${total.toLocaleString("en-IN")})`;
		},
		accessor: "app",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b["Industry Buying"]) ? parseInt(b["Industry Buying"]) : 0), 0);
			return `Industry Buying (${total.toLocaleString("en-IN")})`
		},
		accessor: "Industry Buying",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.Moglix) ? parseInt(b.Moglix) : 0), 0);
			return `Moglix (${total.toLocaleString("en-IN")})`
		},
		accessor: "Moglix",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.Dealer) ? parseInt(b.Dealer) : 0), 0);
			return `Dealer (${total.toLocaleString("en-IN")})`;
		},
		accessor: "Dealer",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.Distributor) ? parseInt(b.Distributor) : 0), 0);
			return `Distributor (${total.toLocaleString("en-IN")})`;
		},
		accessor: "Distributor",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	// {
	// 	Header: row => {
	// 		let { data } = row;
	// 		let total = data.reduce((a, b) => a + (!isNaN(b.Testing) ? parseInt(b.Testing) : 0), 0);
	// 		return `Testing (${total})`;
	// 	},
	// 	accessor: "Testing",
	// },
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.total) ? parseInt(b.total) : 0), 0);
			return `Total (${total.toLocaleString("en-IN")})`;
		},
		accessor: "total",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.onlineTotal) ? parseInt(b.onlineTotal) : 0), 0);
			return `Online Channel(${total.toLocaleString("en-IN")})`;
		},
		accessor: "onlineTotal",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.offlineTotal) ? parseInt(b.offlineTotal) : 0), 0);
			return `Offline Channel(${total.toLocaleString("en-IN")})`;
		},
		accessor: "offlineTotal",
		Cell: row => (parseInt(row.value || 0)).toLocaleString("en-IN")
	}
]

export const weeklyOrderColumns = [
	{
		Header: "Status",
		accessor: "status"
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.orderQty) ? parseInt(b.orderQty) : 0), 0);
			return `Order Qty (${total})`;
		},
		accessor: "orderQty",
	},
	{
		Header: "Percentage",
		accessor: "percentage"
	}
]

export const fulfillmentReportDurationColumns = [
	{
		Header: "Duration",
		accessor: "duration"
	},
	{
		Header: row => {
			let { data } = row;
			let total = data.reduce((a, b) => a + (!isNaN(b.orderQty) ? parseInt(b.orderQty) : 0), 0);
			return `Order Qty (${total})`;
		},
		accessor: "orderQty",
	},
	{
		Header: "Percentage",
		accessor: "percentage"
	}
]


export const subOrderColumns = [
	{
		Header: "Sub Order Id",
		accessor: "subOrderId",
		Cell: row => (
			<div>
				<Link to={"/zunsolar/suborder/" + row.value} onClick={(event) => { event.preventDefault(); window.open(window.location.origin + "/zunsolar/suborder/" + row.value); }}>{row.value}</Link>
			</div>
		)
	},
	{
		Header: "Category",
		accessor: "category",
	},
	{
		Header: "Sub Category",
		accessor: "subCategory",
	},
	{
		Header: "Status",
		accessor: "status",
	},
	{
		Header: "Shipping Partner",
		accessor: "shippingPartner",
	},
	{
		Header: "Tracking Number",
		accessor: "trackingNumber",
	},
	{
		Header: "Pick Up Date",
		accessor: "pickUpDate",
	},
	{
		Header: "Delivery Date",
		accessor: "deliveryDate",
	},
	{
		Header: "Cancellation Date",
		accessor: "cancellationDate"
	},
	{
		Header: "Online Price",
		accessor: "onlinePrice",
	},
	{
		Header: "Platform Fess",
		accessor: "platformFees",
	},
	{
		Header: "Shipping Fees",
		accessor: "shippingFees"
	},
	{
		Header: "Bank Transfer",
		accessor: "bankTransfer"
	},
	{
		Header: "Invoice Number",
		accessor: "invoiceNumber",
	},
	{
		Header: "Lot Number",
		accessor: "lotNo"
	}
];

export const warrantyDetailsZunsolarColumns = [
	{
		Header: "Warranty Id",
		accessor: "warrantyId"
	},
	{
		Header: "order id",
		accessor: "orderId",
	},
	{
		Header: "subOrder Id",
		accessor: "subOrderId",
	},
	{
		Header: "Combo Suborder Id",
		accessor: "subOrderDetails",
	},
	{
		Header: "Warranty Months",
		accessor: "warrantyMonths",
	},
	{
		Header: "category",
		accessor: "category",
	},
	{
		Header: "Sub Category",
		accessor: "subCategory",
	},
	{
		Header: "Product Name",
		accessor: "productName",
	},
	{
		Header: "Product Id",
		accessor: "pid",
	},
	{
		Header: "Mode",
		accessor: "mode",
		Cell: row=>{
			return row.value==1? "Online" : "Offline"
		}
	},
	{
		Header: "Platform",
		accessor: "platform",
	},
	{
		Header: "Accepted By",
		accessor: "adminEmail",
	},
	{
		Header: "Serial Number",
		accessor: "serialNumber",
	},
	{
		Header: "Date of Order",
		accessor: "purchaseDate",
		Cell: row => {
			return moment(+row.value * 1000).format("DD MMM, YYYY")
		}
	},
	{
		Header: "validity",
		accessor: "validity",
		Cell: row => {
			return  row.value &&  moment(+row.value * 1000).format("DD MMM, YYYY")
		}
	},
	{
		Header: "Registered On",
		accessor: "createdAt",
		Cell: row => {
			return moment(row.value).format("DD MMM, YYYY HH:mm:ss")
		}
	},
	
	{
		Header: "Phone",
		accessor: "phoneNumber"
	},
	{
		Header: "Pincode",
		accessor: "pincode"
	},
	{
		Header: "Invoice",
		accessor: "pdfPath"
	},
	{
		Header: "Status",
		accessor: "status",
		Cell: row=>{
			return row.value==0? "Pending" : row.value==1 ?  "Approved" : "Rejected"
		}
	},
	{
		Header: "Download Invoice",
		filterable: true,
		sortable: false,
		columns: false,
		accessor:'pdfPath',
		onClick: () => { alert('click!'); },
		Cell: row => {
			console.log('row', row.original)
			return (
			<div className='row d-flex justify-content-center'>
				{<button disabled={!row.value}  onClick={()=>{handleDownloadWarrantyInvoice(row.original.pdfPath)}} className="btn btn-success btn-sm col-md-10 p-2" type="submit" value="Download Invoice">Preview</button>}
				
			</div>
		 )
		}


	},
	{
		Header: "Approve Warranty",
		filterable: true,
		sortable: false,
		columns: false,
		accessor:'status',
		onClick: () => { alert('click!'); },
		Cell: row => {
			console.log('row', row.original)
			return (
			<div className='row d-flex justify-content-center'>
				{<button  disabled={row.value} onClick={()=>{handleWarrantyStatus(1,row.original.warrantyId)}} className="btn btn-success btn-sm col-md-10 p-2" type="submit" value="Approved">Approve</button>}
				
			</div>
		 )
		}


	},
	{
		Header: "Reject Warranty",
		filterable: true,
		sortable: false,
		columns: false,
		accessor:'status',
		onClick: () => { alert('click!'); },
		Cell: row => {
			console.log('row', row.original)
			return (
			<div className='row d-flex justify-content-center'>
				{<button  disabled={row.value} onClick={()=>{handleWarrantyStatus(2,row.original.warrantyId)}} className="btn btn-warning btn-sm col-md-8 p-2" type="submit" value="Approved">Reject</button>}
				
			</div>
		 )
		}


	}

];


export const handleWarrantyStatus = async(status, warrantyId)=>{
	try {
		console.log('call apporved coupon ');
		let text = "Press a button!\nEither Ok or Cancel.";
		if (window.confirm(text) == true) {
		  text = "You pressed OK!";
	

		const userData =  JSON.parse(localStorage.getItem('userObj'));
		console.log('userData', userData);
		const approvedBy = userData.email || "devteam@zunpulse.com"
		const apiData = {
			adminEmail: approvedBy,
			status: status,
			warrantyId: warrantyId
		}
		console.log('apiData', apiData)
		let warrantyRes = await axios.post(`${zcommerceUrl.productPathBackend}user/upadteWarrantyStatus`,apiData)
		warrantyRes = warrantyRes.data;
		if(warrantyRes && warrantyRes.data && warrantyRes.data.length){
			window.location.reload(false);
			alert("Operation Performed successfully");
			return;
		}
		alert("some error occured");
			return;
		console.log('warrantyres', warrantyRes );
	}
		
	} catch (error) {
		console.log('error', error);
		alert("some error occured");
		return;
		
	}
}

export const handleDownloadWarrantyInvoice = async(fileName)=>{
	try {
		const userData =  JSON.parse(localStorage.getItem('userObj'));
		const email = userData.email || "devteam@zunpulse.com"
		if(!fileName){
			alert("file does not exits");
			return;
		}
		const apiData = {
			email: email,
			fileName: fileName,
			key: "8ALS!Fcm#iWX_IgN"
		}
		console.log('apiData', apiData)
		let invoiceRes = await axios.post(`${url.pathBackend}zunpulse/invoice/downloadWarrantyInvoice`,apiData)
		invoiceRes = invoiceRes.data;
		if(invoiceRes && invoiceRes.fileData && invoiceRes.fileData.link){
			window.open(invoiceRes.fileData.link,"_blank")
			// window.location.reload(false);
			// alert("Operation Performed successfully");
			return;
		}
		
		alert("Invoice not found");
			return;
		// console.log('warrantyres', warrantyRes );
		
		
	} catch (error) {
		console.log('error', error);
		alert("some error occured");
		return;
		
	}
}
const handleDownloadRepeatUser = async(fileName)=>{
	try {
		const userData =  JSON.parse(localStorage.getItem('userObj'));
		const email = userData.email || "devteam@zunpulse.com"
		if(!fileName){
			alert("file does not exits");
			return;
		}
		const apiData = {
			email: email,
			fileName: fileName,
			key: "8ALS!Fcm#iWX_IgN"
		}
		console.log('apiData', apiData)
		let invoiceRes = await axios.post(`${url.pathBackend}zunpulse/repeatSales/downloadRepeatUserData`,apiData)
		invoiceRes = invoiceRes.data;
		if(invoiceRes && invoiceRes.fileData && invoiceRes.fileData.link){
			window.open(invoiceRes.fileData.link,"_blank")
			// window.location.reload(false);
			// alert("Operation Performed successfully");
			return;
		}
		
		alert("Invoice not found");
			return;
		// console.log('warrantyres', warrantyRes );
		
		
	} catch (error) {
		console.log('error', error);
		alert("some error occured");
		return;
		
	}
}

const handleRepeatLiveVersion  = async(versionId)=>{
	try {
		const userData =  JSON.parse(localStorage.getItem('userObj'));
		const email = userData.email || "devteam@zunpulse.com"
		if(!versionId){
			alert("file does not exits");
			return;
		}
		const apiData = {
			email: email,
			versionId: versionId,
			key: "8ALS!Fcm#iWX_IgN"
		}
		let versionRes = await axios.post(`${url.pathBackend}zunpulse/repeatSales/liveRepeatSalesVersion`,apiData)
		versionRes = versionRes.data;
		if(versionRes &&  versionRes.status =="success"){
			window.location.reload(false);
			alert(`Version ${versionId} is live now`);
			return;
		}
	} catch (error) {
		console.log('error', error);
		alert("some error occured");
		return;
	}

}

export const inventoryCsvColumns = [
	{
		label: 'PO Date',
		key: 'date',
	},
	{
		label: "PO No",
		key: "supplyOrderId",
	},
	{
		label: 'Lot No',
		key: "lotNo",
	},
	{
		label: "Category",
		key: "category",
	},
	{
		label: "Sub Category",
		key: "subCategory"
	},
	{
		label: "Invoice No",
		key: "invoiceNo",
	},
	{
		label: "Invoice Date",
		key: "preferenceDate",
	},
	{
		label: "Order Qty",
		key: "orderQty",
	},
	{
		label: "Recieved Qty",
		key: "inStock",
	},
	{
		label: "Unit Price",
		key: "unitPrice",
	},
	{
		label: "GST",
		key: "gst",
	},
	{
		label: "Freight",
		key: "freight",
	},
	{
		label: "Freight Tax",
		key: "freightTax",
	},
	{
		label: "Total",
		key: "total",
	},
	{
		label: "UOM",
		key: "uom",
	},
]

export const zunpulseOrdersCsvColumns = [
	{
		label: "Date",
		key: "viewCustomerOrderDate"
	},
	{
		label: "Order id",
		key: "orderId"
	},
	{
		label: "Platform Order Id",
		key: "platformOrderId"
	},
	{
		label: "Product Name",
		key: "deviceName"
	},
	{
		label: "Customer Name",
		key: "customerName"
	},
	{
		label: "Email",
		key: "email"
	},
	{
		label: "Payment Status",
		key: "paymentStatus"
	},
	{
		label: "Payment Mode",
		key: "paymentMode"
	},
	{
		label: "Delivery Status",
		key: "viewStatus"
	},
	{
		label: "Ordering Date",
		key: "viewOrderDate"
	},
	{
		label: "Shipping Date",
		key: "viewShippingDate"
	},
	{
		label: "Delivery Date",
		key: "viewDeliveryDate"
	},
	{
		label: "Return Date",
		key: "returnDate"
	},
	{
		label: "State",
		key: "deliveryAddress.state"
	},
	{
		label: "City",
		key: "deliveryAddress.city"
	},
	{
		label: "Zipcode",
		key: "deliveryAddress.pincode"
	},
	{
		label: "Tracking Id",
		key: "trackingId"
	},
	{
		label: "Source",
		key: "source"
	},
	{
		label: "Amount Paid",
		key: "amountPaid"
	},
	{
		label: "Mrp",
		key: "mrp"
	},
	{
		label: "Selling Price",
		key: "sellingPrice"
	},
	{
		label: "Coupon",
		key: "coupon"
	},
	{
		label: "Adposition",
		key: "utm.adposition"
	},
	{
		label: "Campaign",
		key: "utm.campaign"
	},
	{
		label: "Device",
		key: "utm.device"
	},
	{
		label: "Keyword",
		key: "utm.keyword"
	},
	{
		label: "loc_interest_ms",
		key: "utm.loc_interest_ms"
	},
	{
		label: "loc_physical_ms",
		key: "utm.loc_physical_ms"
	},
	{
		label: "Matchtype",
		key: "utm.matchtype"
	},
	{
		label: "Medium",
		key: "utm.medium"
	},
	{
		label: "Placement",
		key: "utm.placement"
	},
	{
		label: "Source",
		key: "utm.source"
	},
	{
		label: "Is Pre booked",
		key: "isPreBooked"
	},
	{
		label: "Address",
		key: "address"
	},
]

export const zunpulseNewOrdersCsvColumns = [
	{
		label: "Date",
		key: "viewCustomerOrderDate" //viewCustomerOrderDate
	},
	{
		label: "Order id",
		key: "orderId"
	},
	{
		label: "Display Order Id",
		key: "displayOrderId"
	},
	{
		label: "Platform Order Id",
		key: "platformOrderId"
	},
	{
		label: "Product Name",
		key: "deviceName"
	},
	{
		label: "Quantity",
		key: "quantity"
	},
	{
		label: "Customer Name",
		key: "customerName"
	},
	{
		label: "Email",
		key: "email"
	},
	{
		label: "Payment Status",
		key: "successStatus" //paymentStatus
	},
	{
		label: "Payment Mode",
		key: "paymentMode"
	},
	{
		label: "Delivery Status",
		key: "viewStatus" //viewStatus
	},
	{
		label: "Ordering Date",
		key: "viewOrderDate" //viewOrderDate
	},
	{
		label: "Shipping Date",
		key: "viewShippingDate" //viewShippingDate
	},
	{
		label: "Delivery Date",
		key: "viewDeliveryDate" //viewDeliveryDate
	},
	{
		label: "Return Date",
		key: "returnDate"
	},
	{
		label: "Shipment Location",
		key: "shipmentLocationView"
	},
	{
		label: "State",
		key: "state" //deliveryAddress.state
	},
	{
		label: "City",
		key: "city" //deliveryAddress.city
	},
	{
		label: "Zipcode",
		key: "pincode" //deliveryAddress.pincode
	},
	{
		label: "Tracking Id",
		key: "trackingId"
	},
	{
		label: "Source",
		key: "source"
	},
	{
		label: "Amount Paid",
		key: "paidPrice" //amountPaid
	},
	{
		label: "Mrp",
		key: "totalPrice" //mrp
	},
	{
		label: "Selling Price",
		key: "sellingPrice"
	},
	{
		label: "Coupon Code",
		key: "couponName" //coupon
	},
	{
		label: "utm",
		key: "utm"
	},
	// {
	// 	label: "Adposition",
	// 	key: "utm.adposition"
	// },
	// {
	// 	label: "Campaign",
	// 	key: "utm.campaign"
	// },
	// {
	// 	label: "Device",
	// 	key: "utm.device"
	// },
	// {
	// 	label: "Keyword",
	// 	key: "utm.keyword"
	// },
	// {
	// 	label: "loc_interest_ms",
	// 	key: "utm.loc_interest_ms"
	// },
	// {
	// 	label: "loc_physical_ms",
	// 	key: "utm.loc_physical_ms"
	// },
	// {
	// 	label: "Matchtype",
	// 	key: "utm.matchtype"
	// },
	// {
	// 	label: "Medium",
	// 	key: "utm.medium"
	// },
	// {
	// 	label: "Placement",
	// 	key: "utm.placement"
	// },
	// {
	// 	label: "Source",
	// 	key: "utm.source"
	// },
	// {
	// 	label: "Is Pre booked",
	// 	key: "isPreBooked"
	// },
	{
		label: "Address",
		key: "address"
	},
	{
		label: "Modified On",
		key: "viewModifiedDate"
	},
	{
		label: "Modified By",
		key: "lastUpdatedBy"
	},
	{
		label: "Responsible",
		key: "responsible"
	}
]

export const repeatNotificationFieldConfig = [
	{ name: 'notificationTitle', label: 'Notification Title', type: 'text', required: true },
	{ name: 'notificationMessage', label: 'Notification Message', type: 'text', required: true },
	{ name: 'redirectUrl', label: 'Redirect Url', type: 'text', required: true }
]

export const repeatSalesImageConfig = [

	{ name: 'products', label: 'Select Products', type: 'select', optionsName: 'products', isMulti: true },
	{ name: 'files', label: 'Upload Image', type: 'file', required:true}

]

export const repeatSalesVersionConfig = [

	{ name: 'startDate', label: 'Start Date', type: 'datetime-local',  required:true },
	{ name: 'endDate', label: 'End Date', type: 'datetime-local', required:true},
	{ name: 'userFile', label: 'Users CSV File(ph. with offer code)', type: 'file', required:true},
	

]

export const repeatSalesOfferConfig = [

	{ name: 'discountPercentage', label: 'discount percentage', type: 'number', required:true },
	{ name: 'products', label: 'Select Products', type: 'select', optionsName: 'products', isMulti: true},
	{ name: 'variableBannerCount', label: 'variable Banners Count', type: 'number', required:false },


]

export const repeatSalesBannerConfig = [
	{ name: 'isFixed', label: 'Is Fixed Banner?', type: 'select', optionsName: 'bannerType', isMulti: false, required:true },
	{ name: 'imageId', label: 'select banner Id', type: 'select', optionsName: 'bannerIds', isMulti: false, required:true },
	{ name: 'landingUrl', label: 'Banner Landing Url', type: 'select', optionsName: 'landingUrl', isMulti: false, required:true },
	{ name: 'bannerName', label: 'Banner Name', type: 'text', required: true },
]


const imageBaseUrl = "https://firebasestorage.googleapis.com/v0/b/zunpulse4development/o/"
export const repeatSalesBannerColumnConfig = [
	{
		Header: "Banner Id",
		accessor: "id",
	},
	{
		Header: "BannerID Value",
		accessor: "imageId",
	},
	{
		Header: "View Image",
		accessor: "imageUrl",
		Cell: row=>{
			return(
				<a href={`${imageBaseUrl}${row.original.imageUrl}`} target="_blank">view Image</a>
			)
		}
	},
	{
		Header: "Image Preview",
		accessor: "imageUrl",
		Cell: row=>{
			return(
				<img style={{width:'100px', height:"100px", objectFit:'contain'}} src={`${imageBaseUrl}${row.original.imageUrl}`}  />
			)
		}
	},
	{
		Header: "Products Name",
		accessor: "products",
		Cell: row=>{
			return row.original.products? row.original.products: "All"
		}
	},
	{
		Header: "Products Id's",
		accessor: "pids",
		Cell: row=>{
			return row.original.pids? row.original.pids: "All"
		}
	},

]


export const repeatSalesOfferColumnConfig = [
	{
		Header: "Offer Id",
		accessor: "offerId"
	},
	{
		Header: "Discount Percentage",
		accessor: "discountPercentage",
	},
	{
		Header: "Products Name",
		accessor: "products",
		Cell: row=>{
			return row.original.products ? row.original.products  : "All"
		}
	},
	{
		Header: "Product Pids",
		accessor: "pids",
		Cell: row=>{
			return row.original.pids ? row.original.pids  : "All"
		}
	},
	{
		Header: "variable Banner Count",
		accessor: "variableBannerCount",
	},
	{
		Header: "Created By",
		accessor: "createdBy",
	},
	{
		Header: "Created On",
		accessor: "createdOn",
	},
	{
		Header: "View Banner Data",
		accessor: "offerId",
		Cell: row=>{
			console.log(row.original.bannerData)
			return(
			<div>
				<Link to = {{pathname:`repeatSales/bannerView/${row.original.offerId}` , state:{"bannerData":row.original.bannerData}}}   >View Banner Data</Link>

			</div>
			)
}
	},

]

export const repeatSalesOfferBannerColumnConfig = [
	{
		Header: "Offer Id",
		accessor: "offerId",
	},
	{
		Header: "Is Fixed Banner",
		accessor: "isFixed",
		Cell: row=>{
			return row.original.value?  "Yes": "No"
		}
	},
	{
		Header: "Landing Url",
		accessor: "landingUrl",
	},{
		Header: "imageId",
		accessor: "imageId",
	},{
		Header: "Banner Name",
		accessor: "bannerName",
	},
	{
		Header: "created By",
		accessor: "createdBy",
	}

]

export const repeatSalesVersionColumnConfig = [
	{
		Header: "version Id",
		accessor: "versionId",
	},
	{
		Header: "Start Date",
		accessor: "startEpoch",
		Cell: row => {
			return moment(+row.value ).format("DD MMM, YYYY")
		}
	},
	{
		Header: "End Date",
		accessor: "endEpoch",
		Cell: row => {
			return moment(+row.value ).format("DD MMM, YYYY")
		}
	},{
		Header: "Created By",
		accessor: "createdBy",
	},
	{
		Header: "Download User Data",
		accessor: "userFile",
		filterable: true,
		sortable: false,
		columns: false,
		onClick: () => { alert('click!'); },
		Cell: row => {
			console.log('row', row.original)
			return (
			<div className='row d-flex justify-content-center'>
				{<button  onClick={()=>{handleDownloadRepeatUser(row.original.userFile)}} className="btn btn-success btn-sm col-md-10 p-2" type="submit" value="Download Invoice">Download User File</button>}
				
			</div>
		 )
		}

	},

	// {
	// 	Header: "Live this Version",
	// 	accessor: "versionId",
	// 	filterable: true,
	// 	sortable: false,
	// 	columns: false,
	// 	onClick: () => { alert('click!'); },
	// 	Cell: row => {
	// 		console.log('row', row.original)
	// 		return (
	// 		<div className='row d-flex justify-content-center'>
	// 			{<button  onClick={()=>{handleRepeatLiveVersion(row.original.versionId)}} className="btn btn-success btn-sm col-md-10 p-2" type="submit" value="Download Invoice">Make Live</button>}
				
	// 		</div>
	// 	 )
	// 	}

	// }

]

export const couponFieldsConfig = {
    bulk: [
		{ name: 'prefixName', label: 'Prefix Name', type: 'text', required: true },
		{ name: 'minCartValue', label: 'Min Cart Value', type: 'number', required: true },
        { name: 'maxDiscountValue', label: 'Max Discount Amount', type: 'number', required: true },
		{ name: 'expiryDate', label: 'Expiry Date', type: 'date', required: true },
		{ name: 'discountValue', label: 'Flat Discount Amount', type: 'number', required: true },
        { name: 'firstTimeUser', label: 'First Time User', type: 'select', optionsName: 'firstTimeUser', isMulti: false},
        { name: 'totalCoupons', label: 'Total Coupons', type: 'number', required: true },
        { name: 'product', label: 'Product', type: 'select', optionsName: 'products', isMulti: true }
    ],
    flat: [
		{ name: 'couponName', label: 'Coupon Name', type: 'text', required: true },
		{ name: 'minCartValue', label: 'Min Cart Value', type: 'number', required: true },
        { name: 'maxDiscountValue', label: 'Max Discount Amount', type: 'number', required: true },
		{ name: 'expiryDate', label: 'Expiry Date', type: 'date', required: true },
		{ name: 'discountValue', label: 'Flat Discount Amount', type: 'number', required: true },
        { name: 'firstTimeUser', label: 'First Time User', type: 'select', optionsName: 'firstTimeUser', isMulti: false},
        { name: 'maxUse', label: 'Maximum Usage', type: 'number', required: true },
        { name: 'product', label: 'Product', type: 'select', optionsName: 'products', isMulti: true },
        { name: 'bulkCoupon', label: 'Bulk Coupons', type: 'select', optionsName: 'bulkCoupon', isMulti: false},
        { name: 'totalCoupons', label: 'Total Coupons', type: 'number', required: false },


    ],
    percentage: [
		{ name: 'couponName', label: 'Coupon Name', type: 'text', required: true },
		{ name: 'minCartValue', label: 'Min Cart Value', type: 'number', required: true },
        { name: 'maxDiscountValue', label: 'Max Discount Amount', type: 'number', required: true },
		{ name: 'expiryDate', label: 'Expiry Date', type: 'date', required: true },
		{ name: 'discountValue', label: 'Enter Percentage Value', type: 'number', required: true },
        { name: 'firstTimeUser', label: 'First Time User', type: 'select', optionsName: 'firstTimeUser', isMulti: false},
        { name: 'maxUse', label: 'Maximum Usage', type: 'number', required: true },
        { name: 'product', label: 'Product', type: 'select', optionsName: 'products', isMulti: true },
        { name: 'bulkCoupon', label: 'Bulk Coupons', type: 'select', optionsName: 'bulkCoupon', isMulti: false},
        { name: 'totalCoupons', label: 'Total Coupons', type: 'number', required: false },

		
    ],
	free: [
		{ name: 'couponName', label: 'Coupon Name', type: 'text', required: true },
		{ name: 'minCartValue', label: 'Min Cart Value', type: 'number', required: true },
        { name: 'maxDiscountValue', label: 'Max Discount Amount', type: 'number', required: true },
		{ name: 'expiryDate', label: 'Expiry Date', type: 'date', required: true },
        { name: 'firstTimeUser', label: 'First Time User', type: 'select', optionsName: 'firstTimeUser', isMulti: false},
        { name: 'maxUse', label: 'Maximum Usage', type: 'number', required: true },
        { name: 'bulkCoupon', label: 'Bulk Coupons', type: 'select', optionsName: 'bulkCoupon', isMulti: false},
        { name: 'totalCoupons', label: 'Total Coupons', type: 'number', required: false },


    ],
	BXGY: [
		{ name: 'couponName', label: 'Coupon Name', type: 'text', required: true },
		{ name: 'minCartValue', label: 'Min Cart Value', type: 'number', required: true },
        { name: 'maxDiscountValue', label: 'Max Discount Amount', type: 'number', required: true },
		{ name: 'expiryDate', label: 'Expiry Date', type: 'date', required: true },
        { name: 'firstTimeUser', label: 'First Time User', type: 'select', optionsName: 'firstTimeUser', isMulti: false},
        { name: 'maxUse', label: 'Maximum Usage', type: 'number', required: true },
        { name: 'product', label: 'Product', type: 'select', optionsName: 'products', isMulti: true },
		{ name: 'buyCount', label: 'Buy Quantity', type: 'number', required: true },
		{ name: 'getCount', label: 'Get Free Quantity', type: 'number', required: true },
        { name: 'bulkCoupon', label: 'Bulk Coupons', type: 'select', optionsName: 'bulkCoupon', isMulti: false},
        { name: 'totalCoupons', label: 'Total Coupons', type: 'number', required: false },


    ],
	FreeProduct:[
		{ name: 'productId', label: 'Product', type: 'select', optionsName: 'products', isMulti: false },
		{ name: 'freeCount', label: 'Free Product Count', type: 'number', required: true },

	]
};
export const firstTimeUserMapping = [
	{ label: 'Yes', value: true },
	{ label: 'No', value: false },]

	export const bulkCouponMapping = [
		{ label: 'Yes', value: true },
		{ label: 'No', value: false },]

export const productsMapping = [
    { label: 'smart bulb', value: 'D10000' },
    { label: 'smart bulb 9W', value: 'D10001' },
    { label: 'smart downlight', value: 'D10020' },
    { label: 'smart energy monitor', value: 'D2' },
    { label: 'smart plug', value: 'D20000' },
    { label: 'smart ac remote', value: 'D30000' },
    { label: 'smart tv remote', value: 'D31000' },
    { label: 'smart security', value: 'D40000' },
    { label: 'smart camera', value: 'D50000' },
    { label: 'smart doorbell', value: 'D51000' },
    { label: 'smart downlight pack of 2', value: 'D81000' },
    { label: 'smart downlight & smart plug combo', value: 'D82000' },
    { label: 'smart downlight & smart TV remote combo', value: 'D83000' },
    { label: 'smart downlight & smart AC remote combo', value: 'D84000' },
    { label: 'smart AC remote & smart plug combo', value: 'D85000' },
    { label: 'smart plug & smart TV remote combo', value: 'D86000' },
    { label: 'smart plug & smart bulb 9W combo', value: 'D87000' },
    { label: 'smart doorbell & smart bulb combo', value: 'D91000' },
    { label: 'smart plug & smart bulb combo', value: 'D92000' },
    { label: 'smart bulb & smart AC remote combo', value: 'D93000' },
    { label: 'smart bulb & smart AC remote combo 9W', value: 'D93001' },
    { label: 'smart bulb & smart TV remote combo', value: 'D94000' },
    { label: 'smart bulb & smart TV remote combo 9W', value: 'D94001' },
    { label: 'smart bulb pack of 2', value: 'D95000' },
    { label: 'smart bulb pack of 2 9W', value: 'D95001' },
    { label: 'smart bulb pack of 3', value: 'D96000' },
    { label: 'smart bulb pack of 5', value: 'D97000' },
    { label: 'smart plug pack of 2', value: 'D98000' },
    { label: 'smart plug pack of 3', value: 'D99000' }
];

export const procurementVendorColumns = [
	{
		Header: "Id",
		accessor: "id"
	},
	{
		Header: "Company Name",
		accessor: "companyName"
	},
	{
		Header: "Company Type",
		accessor: "companyType"
	},
	{
		Header: "Address",
		accessor: "address"
	},
	{
		Header: "PAN Card",
		accessor: "panNumber"
	},
	{
		Header: "POC Name",
		accessor: "pocName"
	},
	{
		Header: "Phone Number",
		accessor: "phoneNumber"
	},
	{
		Header: "Email",
		accessor: "email"
	},
	{
		Header: "GST Number",
		accessor: "gst"
	},
	{
		Header: "Delete",
		accessor: "deleteVendor"
	}
];

export const affliateKYCColumns = [
	{
		Header: "Name",
		accessor: "name"
	},
	{
		Header: "Phone Number",
		accessor: "phoneNumber"
	},
	{
		Header: "IFSC",
		accessor: "ifsc"
	},
	{
		Header: "Account Number",
		accessor: "accountNumber"
	},
	{
		Header: "PAN Card",
		accessor: "panCard"
	},
	{
		Header: "Status",
		accessor: "status"
	}
];

export const affiliateListColumns = [
	{
		Header: "Name",
		accessor: "affiliateName"
	},
	{
		Header: "Phone Number",
		accessor: "affiliateNumber"
	},
	{
		Header: "Affiliate Code",
		accessor: "name"
	},
	{
		Header: "Cashback %",
		accessor: "cashbackPercent"
	},
	{
		Header: "Discount%",
		accessor: "discountPercent"
	},
];


export const consolidatedListColumns = [
	{
		Header: "Phone Number",
		accessor: "phoneNumber"
	},
	{
		Header: "KYC done",
		accessor: "status"
	},
	{
		Header: "Code",
		accessor: "name"
	},
	{
		Header: "Net Payable",
		accessor: "Amount"
	}
]

export const detailedListColumns = [
	// {
	// 	Header:"Id",
	// 	accessor: "id"

	// },
	{
		Header: "Phone Number",
		accessor: "phoneNumber"
	},
	{
		Header: "Date",
		accessor: "date"
	},
	{
		Header: "Status",
		accessor: "status"
	},
	{
		Header: "Order Id",
		accessor: "orderId"
	},
	{
		Header: "Amount",
		accessor: "amount"
	},
	{
		Header: "Reference Id",
		accessor: "referenceId"
	}
]

export const companyTypeOptions = [
    { label: "Public Limited", value: "Public Limited" },
    { label: "Pvt Limited", value: "Pvt Limited" },
    { label: "Partnership", value: "Partnership" },
    { label: "Proprietorship", value: "Proprietorship" }
];


export const vendorLedgerColumns = [
	{
		Header: "S. No",
		accessor: "siNo"
	},
	{
		Header: "Date",
		accessor: "date"
	},
	{
		Header: "Order Id",
		accessor: "orderId"
	},
	{
		Header: "Type",
		accessor: "type"
	},
	{
		Header: "Amount",
		accessor: "amount"
	},
	{
		Header: "Reference",
		accessor: "reference"
	},
	{
		Header: "Bank",
		accessor: "bank"
	}
];

export const History = [
	{
		Header: "timestamp",
		accessor: "timestamp"
	},
    {
		Header: "orderId",
		accessor: "orderId"
	},
    {
		Header: "updatedBy",
		accessor: "updatedBy"
	},
    {
		Header: "updatedField",
		accessor: "updatedField"
	},
    {
		Header: "updatedFrom",
		accessor: "updatedFrom"
	},
    {
		Header: "updatedTo",
		accessor: "updatedTo"
	},
]

export const vendorProductsList = [
	{ label: "Panel", value: "Panel" },
	{ label: "Inverter", value: "Inverter" },
	{ label: "Battery", value: "Battery" },
	{ label: "Charge Controller", value: "Charge Controller" },
	{ label: "SMU", value: "SMU" },
	{ label: "DC Wire", value: "DC Wire" },
	{ label: "Connector", value: "Connector" },
	{ label: "Panel Stand", value: "Panel Stand" },
	{ label: "Street Lights", value: "Street Lights" },
	{ label: "Packaging", value: "Packaging" },
	{ label: "Logistics", value: "Logistics" },
	{ label: "Zunvolt Stabilizer", value: "Zunvolt Stabilizer"},
];


export const walletTransactionsConfig = [
	{
		Header: "Phone",
		accessor: "user_id"
	},
	{
		Header: row => {
			let { data } = row;
			let availableCoins = 0;
			data.forEach(row => {
				if(row.expiry && moment(row.expiry).valueOf() > moment().valueOf()) {
					availableCoins += (row.coins - row.redeemed);
				}
			})
			return `Coins ${availableCoins}`
		},
		accessor: "coins"
	},
	{
		Header: "Transaction Value",
		accessor: "transaction_value"
	},
	{
		Header: "Transaction Id",
		accessor: "transaction_id"
	},
	{
		Header: "Date",
		accessor: "transaction_dt"
	},
	{
		Header: "Expiry",
		accessor: "expiry",
		Cell: row => {
			return row.value ? moment(row.value).format("YYYY-MM-DD HH:mm:ss") : ""
		}
	},
	{
		Header: "Redeemed",
		accessor: "redeemed"
	},
	{
		Header: "Added On",
		accessor: "createdAt",
		Cell: row => {
			return moment(row.value).format("YYYY-MM-DD HH:mm:ss")
		}
	}
];


export const redeemedVouchersConfig = [
	{
		Header: "Phone",
		accessor: "user_id"
	},
	{
		Header: "Coins",
		accessor: "redeemCoins"
	},
	{
		Header: "Voucher",
		accessor: "voucher"
	},
	{
		Header: "Transaction Id",
		accessor: "transaction_id"
	},
	{
		Header: "Date",
		accessor: "transaction_dt"
	}
];


export const couponDetailColumnConfig = [
	{
		Header: "Coupon Name",
		accessor: "coupon",
	},
	{
		Header: "Coupon Type",
		accessor: "type"
		
	},
	{
		Header: "Expiry Date",
		accessor: "expiryDate",
		Cell: row => {
			return moment(row.value).format("DD MMM, YYYY HH:mm:ss")
		}
	},
	{
		Header: "Min Cart Value",
		accessor: "minCartValue"
	},
	{
		Header: "Max Discount",
		accessor: "maxDiscount"
	},
	{
		Header: "Value",
		accessor: "value"
	},
	{
		Header: "Bulk Coupon",
		accessor: "prefix",
		Cell: row => row.value?"true":"false"		

	},
	{
		Header: "First Time User",
		accessor: "firstTimeUser",
		Cell: row => row.value?"true":"false"		
	},
	{
		Header: "Usage",
		accessor: "usage"
	}
	,{
		Header: "Status",
		filterable: true,
		sortable: false,
		columns: false,
		accessor:'isApproved',
		onClick: () => { alert('click!'); },
		Cell: row => {
			console.log('row', row.original)
			return (
			<div className='row d-flex justify-content-center'>
				{<button  disabled={row.value} onClick={()=>{handleCouponApproved(row.original)}} className="btn btn-success btn-sm col-md-8 p-2" type="submit" value="Approved">Approve</button>}
				
			</div>
		 )
		}

	}

];

const handleCouponApproved =  async(couponData)=>{
	try {
		console.log('call apporved coupon ');
		const userData =  JSON.parse(localStorage.getItem('userObj'));
		console.log('userData', userData);
		const approvedBy = userData.email || "devteam@zunpulse.com"

		const data = {coupon: couponData.coupon , status:true, approvedBy:approvedBy}
		let couponsRes = await  axios.post(`${zcommerceUrl.couponPathBackend}approvedCoupon`, data);
		couponsRes = couponsRes.data;
		console.log('couponRes', couponsRes);
		if(couponsRes.status!=='success'){
			alert("some error occured");
			return;
		}
		window.location.reload(false);
		alert("approved successfully");
	} catch (error) {
		console.log("error", error)
		alert(error.message|| "some error occured");
	}


}

export const zunsolarOrderHistoryColumns = [
	{
		Header: "Time",
		accessor: "timestamp",
		Cell: row => moment(+row.value).format("YYYY-MM-DD HH:mm:ss")
	},
	{
		Header: "Order Id",
		accessor: "orderId"
	},
	{
		Header: "SubOrder Id",
		accessor: "subOrderId"
	},
	{
		Header: "Updated By",
		accessor: "updatedBy"
	},
	{
		Header: "Updated Field",
		accessor: "updatedField"
	},
	{
		Header: "Updated From",
		accessor: "updatedFrom"
	},
	{
		Header: "Updated To",
		accessor: "updatedTo"
	}
];

export const projectSalesHistoryColumns = [
	{
		Header: "Time",
		accessor: "timestamp",
		Cell: row => moment(+row.value).format("YYYY-MM-DD HH:mm:ss")
	},
	{
		Header: "Updated By",
		accessor: "updatedBy"
	},
	{
		Header: "Updated Field",
		accessor: "updatedField"
	},
	{
		Header: "Updated From",
		accessor: "updatedFrom"
	},
	{
		Header: "Updated To",
		accessor: "updatedTo"
	}
];


export const ticketColumns = [
	{
		Header: "Date",
		accessor: "createdAt",
	},
	{
		Header: "Name",
		accessor: "name",
	},
	{
		Header: "Ticket ID",
		accessor: "ticketId",
		Cell: row => {
			let { ticketId } = row.original;
			return (<div><a href={`/zunsolar/ticket/${ticketId}`} target={`_blank`}>{ticketId}</a></div>)
		}
	},
	{
		Header: "Phone Number",
		accessor: "phoneNumber",
	},
	{
		Header: "Category",
		accessor: "type",
	},
	{
		Header: "Sub-Category",
		accessor: "subtype",
	},
	{
		Header: "Complaint Status",
		accessor: "complaintStatus",
	},
	{
		Header: "Team",
		accessor: "responsibleTeam",
	},
	{
		Header: "Responsible",
		accessor: "responsiblePerson",
	},
	{
		Header: "Platform",
		accessor: "platform",
	},
   
	{
		Header: "Order ID",
		accessor: "orderId",
		Cell: row => {
			let { orderId } = row.original;
			return (<div><a href={`/zunsolar/order/${orderId}`} target={`_blank`}>{orderId}</a></div>)
		}
	},
	{
		Header: "Sub OrderId",
		accessor: "subOrderId"
	},
	{
		Header: "Order Date",
		accessor: "orderDate"
	},
	{
		Header: "RO Client ID",
		accessor: "roClientId",
		Cell: row => {
			let { roClientId } = row.original;
			return ( roClientId && <Link onClick={()=>{window.open(`http://zuntalks.in:3030/order/${roClientId}`)}}>{roClientId}</Link>)
		}
	},
	{
		Header: "RO Product Id",
		accessor: "roProductId",
	},
	{
		Header: "State",
		accessor: "state",
	},
	{
		Header: "City",
		accessor: "city",
	},
	{
		Header: "Ticket Type",
		accessor: "type",
	},
	
	{
		Header: "Reason For Close",
		accessor: "reasonforTicketClosed",
	},
	{
		Header: "Product Id",
		accessor: "productId",
	},
	{
		Header: "Product Name",
		accessor: "productName",
	},
	{
		Header: "Closing Date",
		accessor: "closingDate",
	},
	{
		Header: "Next Action Date",
		accessor: "nextActionDate",
	},
	{
		Header: "B2B",
		accessor: "b2b",
	},
	{
		Header: "Created At",
		accessor: "createdAt",
		Cell: row=>{
			 let date = new Date(row.original.createdAt);
			 console.log('date',date);
			date.setMinutes(date.getMinutes() + 330);
			date=moment(date).format("YYYY-MM-DD HH:mm:ss")
			return date;
		}
	},
	{
		Header: "Last Updated",
		accessor: "updatedAt",
		Cell: row=>{
			let date = new Date(row.original.updatedAt);
			console.log('date',date);
		   date.setMinutes(date.getMinutes() + 330);
		   date=moment(date).format("YYYY-MM-DD HH:mm:ss")
		   return date;
	   }
	},
	// {
	// 	Header: "Download Invoice",
	// 	accessor:'fileUrl',
	// 	minWidth: 150,
	// 	Cell: row => {
	// 		console.log('row', row.original)
	// 		return (
	// 		<div className='row d-flex justify-content-center'>
	// 			{<button disabled={!row.value}  onClick={()=>{handleDownloadTicketAttachment(row.original.fileUrl)}} className="btn btn-success btn-sm col-md-10 p-2" type="submit" value="Download Attachment">Download</button>}
				
	// 		</div>
	// 	 )
	// 	}


	// },
	{
		Header: "Last Comment",
		accessor: "lastComment",
		minWidth: 150,
		Cell: row=>{
			return(
				<div>
					<button id = {`${row.original.ticketId}Btn`} className="btn btn-success btn-sm col-md-10 p-2" type="submit" onClick={()=>fetchLastComment(row.original.ticketId)}>show</button>
					<p id = {row.original.ticketId}></p>
				</div>
			)
		}
	},
	{
		Header: "Created By",
		accessor: "createdBy",
	},
	{
		Header: "Updated By",
		accessor: "updatedBy",
	}

]

export const ticketHistoryColumn = [
	{
		Header: "Time",
		accessor: "timestamp",
		Cell: row => moment(+row.value).format("YYYY-MM-DD HH:mm:ss")

	},
	{
		Header: "Ticket Id",
		accessor: "ticketId",
	},
	{
		Header: "Updated By",
		accessor: "updatedBy",
	},
	{
		Header: "Updated Field",
		accessor: "updatedField",
	},
	{
		Header: "Updated From",
		accessor: "updatedFrom",
	},
	{
		Header: "Updated To",
		accessor: "updatedTo",
	},
]

export const currentCallsColumn = [
	{
		Header: "Date",
		accessor: "Date",
	},
	{
		Header: "Time",
		accessor: "timestamp",
		Cell: row=>{
			let timestamp =  row.original.timestamp;
			timestamp = timestamp?.split(" ") || [0,0];
			return (
				<div>{timestamp[1]}</div>
			)
		}
	},
	{
		Header: "Phone Number",
		accessor: "customerPhoneNumber",
	},
	{
		Header: "Call Duration (In Sec)",
		accessor: "callDuration",
	},
	{
		Header: "Call Type",
		accessor: "callType",
	},
	{
		Header: "Agent Email",
		accessor: "agentEmail",
	},
	{
		Header: "Agent Phone Number",
		accessor: "agentPhoneNumber",
	},
	{
		Header: "Option Selected",
		accessor: "optionSelected",
		Cell: row=>{
			return(
				<div>
					{(row.original.optionSelected)}
				</div>
			)
		}
	},
	{
		Header: "Order Ids",
		accessor: "orderIds",
		Cell: row => {
			let { orderIds=[] } = row.original;
			return (
				orderIds.map((orderId)=>(
					<div><a href={`/zunsolar/order/${orderId}`} target={`_blank`}>{orderId}</a></div>)
				)
				)
			}
		
	},
	{
		Header: "Ticket Ids",
		accessor: "ticketIds",
		minWidth:150,
		Cell: row => {
			let { ticketIds=[] } = row.original;
			return (
				ticketIds.map((ticket)=>(
					<div><a href={`/zunsolar/ticket/${ticket.ticketId}`} target={`_blank`}>{ticket.ticketId +" "+ticket.ticketType}</a></div>)
				)
				)
			}
	}

]

export const outBoundsCallsColumn = [
	{
		Header: "Date",
		accessor: "Date",
		Cell: row=>{
			let timestamp =  row.original.timestamp;
			timestamp = timestamp?.split(" ") || [];
			return (
				<div>{timestamp[0]}</div>
			)
		}
	},
	{
		Header: "Time",
		accessor: "timestamp", 
		Cell: row=>{
			let timestamp =  row.original.timestamp;
			timestamp = timestamp?.split(" ") || [];
			return (
				<div>{timestamp[1]}</div>
			)
		}
	},
	{
		Header: "Phone Number",
		accessor: "customerPhoneNumber",
	},
	{
		Header: "Call Status",
		accessor: "status",
	},
	{
		Header: "Call Duration (In Sec)",
		accessor: "callDuration",
	},
	{
		Header: "Call Type",
		accessor: "callType",
	},
	{
		Header: "Agent Email",
		accessor: "agentEmail",
	},
	{
		Header: "Agent Phone Number",
		accessor: "agentPhoneNumber",
	},
	{
		Header: "Option Selected",
		accessor: "optionSelected",
	},
	{
		Header: "Call Recording",
		accessor: "recording",
		minWidth:250,
		Cell: row=> {
			let url = row.original.recording;
			if(!url || url=='null'){
				return <div>not available</div>
			}
			return(
				<div>
					{/* <audio controls autoplay>
						<source src={url} type="audio/mp3" />
					</audio> */}
					<a href={url} target="_blank">Link</a>
				</div>
			)
		}
	},
	{
		Header: "Order Ids",
		accessor: "orders",
		Cell: row => {
			let { orders=[] } = row.original;
			return (
				orders.map((orderId)=>(
					<div><a href={`/zunsolar/order/${orderId}`} target={`_blank`}>{orderId}</a></div>)
				)
				)
			}
		
	},
	{
		Header: "Ticket Ids",
		accessor: "ticketIds",
		Cell: row => {
			let { ticketIds=[] } = row.original;
			return (
				ticketIds.map((ticket)=>(
					<div><a href={`/zunsolar/ticket/${ticket.ticketId}`} target={`_blank`}>{ticket.ticketId +" "+ticket.ticketType}</a></div>)
				)
				)
			}
	},
	{
		Header:"Add Call",
		accessor:"customerPhoneNumber",
		Cell: row => {
			let {customerPhoneNumber} = row.original;
			let email  = JSON.parse(localStorage.getItem("userObj")).email;
			return(
				<div className='row d-flex justify-content-center'>
					<button className="btn btn-success btn-sm col-md-10 p-2" type="submit" onClick={()=>handleConnectOutboundCall(email,customerPhoneNumber)}>Call</button>
				</div>
			)

		}
	},
	// {
	// 	Header:"Send rating message",
	// 	accessor:"callSid",
	// 	Cell: row => {
	// 		let { callSid, callRating, status } = row.original;
	// 		return(
	// 			(callRating || status != "completed") ? "" : <div className='row d-flex justify-content-center'>
	// 				<button className="btn btn-success btn-sm col-md-10 p-2" type="submit" onClick={()=>handleSendRatingMessage(callSid)}>Send</button>
	// 			</div>
	// 		)

	// 	}
	// },
	{
		Header: "Call Rating",
		accessor: "callRating"
	}
	

]

export const outboundColumnsCsv = [
	{
		label: "Time",
		key: "timestamp"
	},
	{
		label: "Call Status",
		key: "status"
	},
	{
		label: "Call Duration (In Sec)",
		key: "callDuration",
	},
	{
		label: "Call Type",
		key: "callType",
	},
	{
		label: "Agent Email",
		key: "agentEmail",
	},
	{
		label: "Agent Phone Number",
		key: "agentPhoneNumber",
	},
	{
		label: "Call Rating",
		key: "callRating"
	}
];

export const outBoundsCallsColumnIS = [
	{
		Header: "Date",
		accessor: "Date",
		Cell: row=>{
			let timestamp =  row.original.timestamp;
			timestamp = timestamp?.split(" ") || [];
			return (
				<div>{timestamp[0]}</div>
			)
		}
	},
	{
		Header: "Time",
		accessor: "timestamp", 
		Cell: row=>{
			let timestamp =  row.original.timestamp;
			timestamp = timestamp?.split(" ") || [];
			return (
				<div>{timestamp[1]}</div>
			)
		}
	},
	{
		Header: "Phone Number",
		accessor: "customerPhoneNumber",
	},
	{
		Header: "Call Status",
		accessor: "status",
	},
	{
		Header: "Call Duration (In Sec)",
		accessor: "callDuration",
	},
	{
		Header: "Call Type",
		accessor: "callType",
	},
	{
		Header: "Agent Email",
		accessor: "agentEmail",
	},
	{
		Header: "Agent Phone Number",
		accessor: "agentPhoneNumber",
	},
	{
		Header: "Call Recording",
		accessor: "recording",
		minWidth:250,
		Cell: row=> {
			let url = row.original.recording;
			if(!url || url=='null'){
				return <div>not available</div>
			}
			return(
				<div>
					<audio controls autoplay>
						<source src={url} type="audio/mp3" />
					</audio>
				</div>
			)
		}
	},
	{
		Header: "Lead Id",
		accessor: "leadIds",
		Cell: row => {
			let { leadIds = [] } = row.original;
			return (
				leadIds.map((leadId)=>(
					<div><a href={`/lead/details/${leadId}`} target={`_blank`}>{leadId}</a></div>)
				)
				)
			}
		
	}
]

export const handleConnectOutboundCall = async(email,phoneNumber)=>{
	try {
		let params = {
			apiKey:"0a54MRNuam39SO8",
			agent:email,
			phoneNumber:phoneNumber
		}
		let apiRes = await axios.get(`${url.pathBackend}ivrCalls/connectCalls`, {params});
		apiRes = apiRes.data;
		console.log('apiRes', apiRes);
		alert("call connecting.. please check your phone");
		
	} catch (error) {
		console.log('error', error)
		alert("something went wrong")
		
	}
}

export const handleSendRatingMessage = async (callSid) => {
	try {
		console.log("send rating message", callSid);
		let apiRes = await axios.post(`${url.pathBackend}ivrCalls/sendCallRatingMessage`, {
			callSid: callSid
		});
		apiRes = apiRes.data || {};
		if(apiRes.error) {
			alert(apiRes.error);
			return;
		}
		alert("Message sent");
		return;
	}
	catch(error) {
		console.log("Error in handleSendRatingMessage", error);
		return;
	}
}

export const handleConnectOutboundCallIS = async(email,phoneNumber)=>{
	try {
		let params = {
			key:"P0GeZbZGdpz3FE9",
			agent:email,
			phoneNumber:phoneNumber
		}
		let apiRes = await axios.get(`${url.pathBackend}insideSalesIvr/connect-call`, {params});
		apiRes = apiRes.data;
		console.log('apiRes', apiRes);
		if(apiRes.error) {
			alert(apiRes.error);
			return;
		}
		alert("Call connecting.. please check your phone");
		
	} catch (error) {
		console.log('error', error)
		alert("something went wrong")
		
	}
}

const fetchLastComment = async(ticketId) =>{
	try {
		let apiData = {
			ticketId:ticketId,
			last:1
		}
		let apiRes = await axios.post(`${url.pathBackend}zunsolar/ivrTicketing/fetchComments`, apiData);
		apiRes = apiRes.data;
		if(apiRes.status == "success"){
			let comments = apiRes.comment || [];
			if(comments && comments.length>0){
				let comment = comments[0]?.commentBody
				document.getElementById(ticketId).innerHTML = comment
				document.getElementById(`${ticketId}Btn`).style.display = 'none';

			}
			else{
				alert("No Comment Found")
			}
		}
		else{
			alert("No Comment Found")
		}
		
	} catch (error) {
		console.log("error", error)
		alert("something went wrong")
		
	}
}



export const handleDownloadTicketAttachment = async(fileName)=>{
	try {
		console.log('fileName',fileName);
		const userData =  JSON.parse(localStorage.getItem('userObj'));
		const email = userData.email || "devteam@zunpulse.com"
		if(!fileName){
			alert("file does not exits");
			return;
		}
		const apiData = {
			email: email,
			fileName: fileName,
			key: "8ALS!Fcm#iWX_IgN"
		}
		console.log('apiData', apiData)
		let invoiceRes = await axios.post(`${url.pathBackend}zunsolar/ivrTicketing/downloadTicketAttachment`,apiData)
		invoiceRes = invoiceRes.data;
		if(invoiceRes && invoiceRes.fileData && invoiceRes.fileData.link){
			window.open(invoiceRes.fileData.link,"_blank")
			return;
		}
		
		alert("Invoice not found");
			return;
		// console.log('warrantyres', warrantyRes );
		
		
	} catch (error) {
		console.log('error', error);
		alert("some error occured");
		return;
		
	}
}

export const projectSalesLeadColumns = [
	{
		Header: 'Lead Id',
		accessor: 'leadId',
		Cell: row => {
			let { leadId } = row.original;
			return (<div><a href={`/projectsales/lead/${leadId}`} target={`_blank`}>{leadId}</a></div>)
		}
	},
	{
		Header: 'Project Name',
		accessor: 'projectName'
	},
	{
		Header: 'Developer',
		accessor: 'developer'
	},
	{
		Header: 'Project Stage',
		accessor: 'projectStage'
	},
	{
		Header: 'Lead Status',
		accessor: 'status'
	},
	{
		Header: 'City',
		accessor: 'city'
	},
	{
		Header: 'Next Action Date',
		accessor: 'nextActionDate'
	},
	{
		Header: 'Launch Date',
		accessor: 'launchDate'
	},
	{
		Header: 'Responsible',
		accessor: 'responsible'
	},
	{
		Header: 'Address',
		accessor: 'address'
	},
	{
		Header: 'Possession Date',
		accessor: 'possessionDate'
	},
	{
		Header: 'Smart Provision',
		accessor: 'smartProvision'
	},
	{
		Header: 'Project Type',
		accessor: 'projectType'
	},
	{
		Header: 'Last Visit Date',
		accessor: 'lastVisitDate'
	},
	{
		Header: 'POC 1 Name',
		accessor: 'poc1Name'
	},
	{
		Header: 'POC 1 Team',
		accessor: 'poc1Team'
	},
	{
		Header: 'POC 1 Phone',
		accessor: 'poc1Phone'
	},
	{
		Header: 'POC 2 Name',
		accessor: 'poc2Name'
	},
	{
		Header: 'POC 2 Team',
		accessor: 'poc2Team'
	},
	{
		Header: 'POC 2 Phone',
		accessor: 'poc2Phone'
	},
	{
		Header: 'POC 3 Name',
		accessor: 'poc3Name'
	},
	{
		Header: 'POC 3 Team',
		accessor: 'poc3Team'
	},
	{
		Header: 'POC 3 Phone',
		accessor: 'poc3Phone'
	},
	{
		Header: 'Last Updated By',
		accessor: 'updatedBy'
	},
	{
		Header: 'Last Updated On',
		accessor: 'updatedAt'
	}
];

export const roofTrackerRegionColumns = [
	{
		Header: 'Region',
		accessor: 'REGION'
	},
	{
		Header: 'SA Setup',
		accessor: 'saSetup'
	},
	{
		Header: 'SA Done',
		accessor: 'saDone'
	},
	{
		Header: 'Roof Sold',
		accessor: 'roofSold'
	},
	{
		Header: 'Roofs Sold(kW)',
		accessor: 'systemSize'
	},
	{
		Header: 'Average Selling Price',
		accessor: 'averagePrice'
	},
	{
		Header: 'Total Revenue',
		accessor: 'finalPrice'
	}
];


export const roofTrackerEmployeeColumns = [
	{
		Header: 'Employee',
		accessor: 'N_RESPONSIBLE'
	},
	{
		Header: 'SA Done',
		accessor: 'saDone'
	},
	{
		Header: 'Roof Sold',
		accessor: 'roofSold'
	},
	{
		Header: 'Average Selling Price',
		accessor: 'averagePrice'
	},
	{
		Header: 'Total Revenue',
		accessor: 'finalPrice'
	}
];